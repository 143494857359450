import React, { useEffect } from "react";
import { fetchAllPlans } from "../../store/plans";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import PlansTable from "../../components/Premium Plans/PlansTable";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const PremiumPlans = () => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state.plans.status);

  useEffect(() => {
    dispatch(fetchAllPlans());
  }, [dispatch]);

  const data = useSelector((state) => state.plans.allPlans);

  const response = useSelector((state) => state.plans.fetchPlansResponse);

  useEffect(() => {
    if (response?.error?.code === "auth/id-token-expired") {
      dispatch(authActions.setTokenError(true));

      sessionExpireLogin();
    }
  }, []);

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
            <p className="fetchText"> Fetching plans.. </p>
          </div>
        </div>
      ) : (
        <>
          <ToastContainer />

          {response?.error?.code === "auth/id-token-expired" ? (
            <h1 className="noData">No plans at the moment</h1>
          ) : (
            <PlansTable tableData={data} type={"Plans"} />
          )}
        </>
      )}
    </>
  );
};

export default PremiumPlans;
