import React from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/UserBig.png";
import styles from "../../styles/adDetail.module.css";
import cross from "../../assets/icons/cancelCross.png";
import cross2 from "../../assets/icons/cancel2.png";
import alert from "../../assets/icons/saveTick.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllUsers } from "../../store/users";
import { useRef } from "react";
import { BarLoader } from "react-spinners";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";
import Cookies from "js-cookie";

const ConfirmDeleteCategoryModal = ({
  openActivateModal,
  setOpenActivateModal,
  id,
  notifySuccess,
  notifyError,
}) => {
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let btnRef = useRef();

  const [loading, setLoading] = React.useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const onDeleteClick = () => {
    setLoading(true);
    var myHeaders = new Headers();
    btnRef.current.setAttribute("disabled", "disabled");

    myHeaders.append("firebasetoken", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      active: true,
    });
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/admin/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setLoading(false);
          dispatch(fetchAllUsers());
          setOpenActivateModal(false);
          notifySuccess();
          setTimeout(() => {
            navigate("/users/activeUsers");
          }, 3000);
        }

        if (result.error?.code === "auth/id-token-expired") {
          setOpenActivateModal(false);
          setLoading(false);
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
      });
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openActivateModal}
      shouldCloseOnEsc
      onRequestClose={() => setOpenActivateModal(false)}
      style={bg}
    >
      <div className={styles.removeAd}>
        <img
          className={styles.cancelCross}
          src={cross}
          alt="X"
          onClick={() => setOpenActivateModal(false)}
        />
        <img alt="Delete" src={confirmRemove} />
        <h1>Are you sure want to activate this user?</h1>
        <div className={styles.actionButtons}>
          <button
            ref={btnRef}
            onClick={() => onDeleteClick()}
            className="btn-save"
          >
            {loading ? (
              <BarLoader color="white" />
            ) : (
              <>
                <img src={alert} alt="save" /> Confirm
              </>
            )}
          </button>
          <button
            className="btn-cancel"
            onClick={() => setOpenActivateModal(false)}
          >
            <img src={cross2} alt="cancel" />
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteCategoryModal;
