import React from "react";
import styles from "../../styles/addCategory.module.css";
import chevLeft from "../../assets/icons/chevleft.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import userAlt from "../../assets/images/gallery.svg";
import "react-dropdown/style.css";
import { getPlanById } from "../../store/plans";
import { useEffect } from "react";
import { BarLoader } from "react-spinners";

const ViewPlan = () => {
  const selectedPlanObject = useSelector(
    (state) => state.plans.selectedPlanObject
  );

  const getCategoryByIdStatus = useSelector(
    (state) => state.plans.getPlanByIdResponse
  );

  const idRaw = useParams();

  const id = String(idRaw.planId).slice(1);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedPlanObject.length) {
      dispatch(getPlanById(id));
    }
  }, []);

  const navigate = useNavigate();

  const schema = Yup.object().shape({
    currency: Yup.string().required("Currency is required"),
    duration: Yup.string().required("Duration is required"),
    price: Yup.string().required("Price is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Name is description"),
  });

  const image = selectedPlanObject[0]?.images;

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  return (
    <div className="containerAdCategory">
      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate(-1)}>
          <img src={chevLeft} alt="back" />
        </button>

        <h1 className="s20">View Plan</h1>
      </div>

      <ToastContainer />

      {getCategoryByIdStatus === "loading" ? (
        <BarLoader color="red" />
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            currency: selectedPlanObject[0]?.currency,
            duration: selectedPlanObject[0]?.duration,
            price: selectedPlanObject[0]?.price,
            name: selectedPlanObject[0]?.name,
            description: selectedPlanObject[0]?.description,
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className={styles.addCategory}>
              <form noValidate onSubmit={handleSubmit}>
                <img src={image} onError={onImageError} alt="Plan" />

                <div className={styles.catName}>
                  <label>Plan Name</label>
                  <input
                    type="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    id="planName"
                    placeholder="Enter plan name"
                    disabled
                  />

                  <p className="red">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>

                <div className={`${styles.catName}`}>
                  <label>Description</label>
                  <textarea
                    type="duration"
                    name="duration"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    disabled
                  />
                </div>

                <div className={`${styles.catName}`}>
                  <label>Duration</label>
                  <input
                    type="duration"
                    name="duration"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.duration}
                    placeholder="Enter plan duration"
                    disabled
                  />

                  <p className="red">
                    {errors.duration && touched.duration && errors.duration}
                  </p>
                </div>

                <div className={styles.catName}>
                  <label>Currency</label>
                  <input
                    type="currency"
                    name="currency"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currency}
                    placeholder="Enter plan currency"
                    disabled
                  />

                  <p className="red">
                    {errors.currency && touched.currency && errors.currency}
                  </p>
                </div>

                <div className={styles.catName}>
                  <label>Price</label>
                  <input
                    type="price"
                    name="price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    placeholder="Enter plan price"
                    disabled
                  />

                  <p className="red">
                    {errors.price && touched.price && errors.price}
                  </p>
                </div>

                <div className={styles.actionButtons}></div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ViewPlan;
