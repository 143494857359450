import React, { useEffect, useState } from "react";
import styles from "../../styles/addCategory.module.css";
import chevLeft from "../../assets/icons/chevleft.png";
import tick from "../../assets/icons/saveTick.png";
import cross from "../../assets/icons/cancel2.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllBanners, getBannerById } from "../../store/banners";
import { UploadEdit } from "./UploadEdit";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { Formik } from "formik";
import * as Yup from "yup";
import { BarLoader, ScaleLoader } from "react-spinners";
import moment from "moment";
import { useRef } from "react";
import { sessionExpireLogin } from "../../firebase";
import { authActions } from "../../store/auth";
import Cookies from "js-cookie";

const UpdateBanner = () => {
  const token = Cookies.get("token");
  const [saveLoad, setSaveLoad] = useState(false);
  const [imageUploadLoader, setImageUploadLoader] = useState(false);
  const dispatch = useDispatch();

  const selectedBannerId = useSelector(
    (state) => state.banners.selectedBannerID
  );
  let btnRef = useRef();

  const selectedBannerObject = useSelector(
    (state) => state.banners.selectedBannerObject
  );

  const idRaw = useParams();
  const id = String(idRaw.bannerId).slice(1);

  useEffect(() => {
    if (!selectedBannerObject?.length) {
      dispatch(getBannerById(id));
    }
  }, []);
  const notifySuccess = () =>
    toast.success("Banner updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const [uploadedFileURL, setUploadedFileUrl] = useState("");

  const navigate = useNavigate();

  const schema = Yup.object().shape({
    bannerTitle: Yup.string().required("Title is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
  });

  const [enable, setEnable] = useState(false);

  const onDisableBanner = (body) => {
    var myHeaders = new Headers();
    setEnable(true);
    myHeaders.append("firebasetoken", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      active: selectedBannerObject[0].active ? false : true,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/admin/banner/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "error") {
          setEnable(false);

          notifyError("Error, All fields are required");
        }
        if (result.status === true) {
          setEnable(false);
          dispatch(fetchAllBanners());
          notifySuccess();
          setTimeout(() => {
            navigate("/banners");
          }, 3000);
        }
      })
      .catch((error) => {
        notifyError();
      });
  };

  const addBanner = (body) => {
    setSaveLoad(true);
    btnRef.current.setAttribute("disabled", "disabled");

    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: body.bannerTitle,
      startDate: moment(body.startDate).format(),
      endDate: moment(body.endDate).format(),
      bannerImage: uploadedFileURL,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/banner/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error?.code === "auth/id-token-expired") {
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
        if (result.status === true) {
          dispatch(fetchAllBanners());
          notifySuccess();
          setTimeout(() => {
            navigate("/banners");
          }, 2000);
          setSaveLoad(false);
        }
      })
      .catch((error) => {
        notifyError();
      });
  };

  const onFileDrop = (files) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);
    setImageUploadLoader(true);

    var formdata = new FormData();
    formdata.append("file", files[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.angolazone.com/api/file-upload/file", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUploadedFileUrl(result.url);
        setImageUploadLoader(false);
      });
  };

  const setButtonText = () => {
    if (selectedBannerObject[0]?.active && !enable) {
      return "Disable Banner";
    }

    if (!selectedBannerObject[0]?.active && !enable) {
      return "Enable Banner";
    }

    if (enable) {
      return <BarLoader color="white" />;
    }
  };

  const getBannerByIdStatus = useSelector(
    (state) => state.banners.getBannerByIdResponse
  );

  return (
    <div className="containerAdCategory">
      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate("/banners")}>
          <img src={chevLeft} alt="back" />
        </button>

        <h1 className="s20">Update Banner</h1>
      </div>

      <ToastContainer />

      {getBannerByIdStatus === "loading" ? (
        <BarLoader color="red" />
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            bannerTitle: selectedBannerObject[0]?.title,

            startDate: moment(selectedBannerObject[0]?.startDate).format(
              "YYYY-MM-DD"
            ),

            endDate: moment(selectedBannerObject[0]?.endDate).format(
              "YYYY-MM-DD"
            ),
          }}
          onSubmit={(values) => {
            addBanner({
              bannerTitle: values.bannerTitle,
              startDate: values.startDate,
              endDate: values.endDate,
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className={styles.addCategory}>
              <button
                onClick={() =>
                  onDisableBanner({
                    token,
                  })
                }
                className={
                  selectedBannerObject[0]?.active
                    ? styles.deactivatedStatus
                    : styles.activeStatus
                }
              >
                {setButtonText()}
              </button>

              <UploadEdit
                onDrop={(files) => onFileDrop(files)}
                img={selectedBannerObject[0]?.bannerImage}
                imageUploadLoader={imageUploadLoader}
              />

              <form noValidate onSubmit={handleSubmit}>
                <div className={styles.catName}>
                  <label>Banner Title</label>
                  <input
                    type="text"
                    name="bannerTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bannerTitle}
                    id="bannerTitle"
                    placeholder="Enter banner name"
                  />

                  <p className="red">
                    {errors.bannerTitle &&
                      touched.bannerTitle &&
                      errors.bannerTitle}
                  </p>
                </div>

                <div className={styles.flexRow}>
                  <div className={styles.catDesc}>
                    <label>Start Date</label>
                    <input
                      className={styles.dateInput}
                      type="date"
                      name="startDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.startDate}
                      placeholder="Enter start date"
                    />

                    <p className="red">
                      {errors.startDate &&
                        touched.startDate &&
                        errors.startDate}
                    </p>
                  </div>

                  <div className={styles.catDesc}>
                    <label>End Date</label>
                    <input
                      className={styles.dateInput}
                      type="date"
                      name="endDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.endDate}
                      placeholder="Enter end date"
                    />

                    <p className="red">
                      {errors.endDate && touched.endDate && errors.endDate}
                    </p>
                  </div>
                </div>

                <div className={styles.actionButtons}>
                  <button className="btn-save" type="submit" ref={btnRef}>
                    {!saveLoad ? (
                      <>
                        <img src={tick} alt="save" /> Update
                      </>
                    ) : (
                      <ScaleLoader color="white" />
                    )}
                  </button>
                  <button
                    className="btn-cancel"
                    onClick={() => navigate("/banners")}
                  >
                    <img src={cross} alt="cancel" />
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UpdateBanner;
