import React, { useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import chevRightRed from "../../assets/icons/chevRight.png";
import chevLeftRed from "../../assets/icons/chevLeftRed.png";
import styles from "../Categories Table/cat.module.css";
import { adsActions } from "../../store/ads";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import trash from "../../assets/icons/trash2.png";
import eye from "../../assets/icons/EyeNew.png";
import { ScaleLoader } from "react-spinners";
import userAlt from "../../assets/images/gallery.svg";
import ConfirmDeleteAdModal from "./ConfirmAdDelete.js";
import Cookies from "js-cookie";
import star from "../../assets/images/premiumStar.png";

const UserAdsTable = ({ tableData, selectedUser }) => {
  const navigate = useNavigate();
  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  const [loadingView, setLoadingView] = useState(false);

  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedID, setSelectedID] = useState("");

  const onDeleteAdClick = (id) => {
    setOpenDeleteModal(true);
    setSelectedID(id);
  };

  const notifySuccess = () =>
    toast.success("Ad deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const data = useMemo(() => tableData, []);

  const sessionToken = Cookies.get("token");

  const onRowClick = (id) => {
    setLoadingView(true);

    dispatch(adsActions.setSelectedAd(id));
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", sessionToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/admin/ad/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(adsActions.setSelectedObject(result.ad));
        navigate("/ads/ad");
        setLoadingView(false);
      });
  };

  const COLUMNS_ADSTABLE = [
    {
      Header: () => <p className={styles.headerID}>Ad ID</p>,
      accessor: "id",
      Cell: (tableProps) => (
        <p
          className={`${styles.id} 
         ${
           tableProps.row.original.adStatus && !tableProps.row.original.isAdSold
             ? styles.activeAd
             : styles.inactiveAd
         }   `}
        >
          {String(tableProps.row.original.id).slice(-4)}
        </p>
      ),
    },
    {
      Header: () => <p className={"d-none"}></p>,
      accessor: "premium",
      Cell: (tableProps) => (
        <p className={"d-none"}>{tableProps.row.original.adIsPremium}</p>
      ),
    },

    {
      Header: "Image",
      accessor: "adImage",
      Cell: (tableProps) => (
        <div>
          <img
            onError={onImageError}
            className="adsImage"
            alt="AD"
            src={tableProps.row.original.adImage[0]}
          />
        </div>
      ),
    },
    {
      Header: "Ad name",
      accessor: "adName",
      Cell: (tableProps) => (
        <button className="btn-noBorder centerAlign">
          {tableProps.row.original.adIsPremium ? (
            <img className="star" src={star} />
          ) : (
            ""
          )}
          {tableProps.row.original.adName}
        </button>
      ),
    },

    {
      Header: "Price",
      accessor: "adPrice",
      Cell: (tableProps) => (
        <p className="red price">$ {tableProps.row.original.adPrice}</p>
      ),
    },
    {
      Header: "Location",
      accessor: "adLocation.address",
      Cell: (tableProps) => (
        <p className="">{`${tableProps.row.original.adLocation.address}`}</p>
      ),
    },

    {
      Header: "Reported",
      accessor: "remarksCount",
      Cell: (tableProps) => (
        <p className="red">{`${tableProps.row.original.remarksCount}`}</p>
      ),
    },

    {
      Header: "Remark",
      accessor: "adRemark",
      Cell: (tableProps) => (
        <p className="red">{`${
          tableProps.row.original.isAdSold
            ? "Sold"
            : tableProps.row.original.adRemark
        }`}</p>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-delete2"
            onClick={() => onRowClick(tableProps.row.original._id)}
          >
            {loadingView === false ? (
              <img src={eye} alt="View" />
            ) : (
              <ScaleLoader color="white" height={20} width={2} />
            )}
          </button>

          <button
            className="btn-delete"
            onClick={() => onDeleteAdClick(tableProps.row.original._id)}
          >
            <img src={trash} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_ADSTABLE, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => {
    //     return [
    //       {
    //         id: "selection",
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <Checkbox {...getToggleAllRowsSelectedProps()} />
    //         ),
    //         Cell: ({ row }) => (
    //           <Checkbox {...row.getToggleRowSelectedProps()} />
    //         ),
    //       },
    //       ...columns,
    //     ];
    //   });
    // }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
  } = tableInstance;

  const { pageIndex } = state;

  return (
    <>
      <ToastContainer />

      <ConfirmDeleteAdModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        id={selectedID}
        notifySuccess={notifySuccess}
        notifyError={notifyError}
        userId={selectedUser}
        // setLoadingView={setLoadingView}
      />
      {tableData.length === 0 ? (
        <h1>No ads posted by this user</h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            <div className="pageInfo">
              {"\u00a0"} Go to page number : {"\u00a0"}
              <input
                type="number"
                default={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </div>
            <div className="buttons">
              <button
                className="jumpButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {" "}
                Go to first page
              </button>
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              {"\u00a0"}
              {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              <button
                className="jumpButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Go to last page
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserAdsTable;
