import React, { useState } from "react";
import styles from "../../styles/addCategory.module.css";
import chevLeft from "../../assets/icons/chevleft.png";
import tick from "../../assets/icons/saveTick.png";
import cross from "../../assets/icons/cancel2.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllCategories } from "../../store/categories";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { Formik } from "formik";
import * as Yup from "yup";
import { ScaleLoader } from "react-spinners";
import { useRef } from "react";
import Cookies from "js-cookie";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Upload } from "./Upload";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const AddPlan = () => {
  const [saveLoad, setSaveLoad] = useState(false);

  let btnRef = useRef();

  const [selectedDuration, setSelectedDuration] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});

  const durationOptions = [
    {
      value: "day",
      label: "Day",
    },
    {
      value: "week",
      label: "Week",
    },
    {
      value: "month",
      label: "Month",
    },

    {
      value: "year",
      label: "Year",
    },
  ];

  const currencyOptions = [
    {
      value: "usd",
      label: "USD $",
    },
  ];

  const notifySuccess = () =>
    toast.success("Plan Created", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const dispatch = useDispatch();

  const [uploadedFileURL, setUploadedFileUrl] = useState("");

  const navigate = useNavigate();

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    duration: Yup.string().required("Duration is required"),
    price: Yup.string().required("Price is required"),
  });

  const [imageError, setImageError] = useState(false);

  const addCategory = (body) => {
    setSaveLoad(true);
    btnRef.current.setAttribute("disabled", "disabled");
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      currency: selectedCurrency.value,
      duration: body.duration + " " + selectedDuration.value + "s",
      price: body.price,
      name: body.name,
      images: uploadedFileURL,
      description: body.description,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.angolazone.com/api/Order/plans", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error?.code === "auth/id-token-expired") {
          setSaveLoad(false);

          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
        if (result.status === true) {
          dispatch(fetchAllCategories());
          notifySuccess();
          setTimeout(() => {
            navigate("/premiumplans");
          }, 2000);
          setSaveLoad(false);
        }
      })
      .catch((error) => {});
  };

  const onDurationSelect = (item) => {
    setSelectedDuration(item);
  };

  const onCurrencySelect = (item) => {
    setSelectedCurrency(item);
  };

  const token = Cookies.get("token");

  const onFileDrop = (files) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);

    var formdata = new FormData();
    formdata.append("file", files[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.angolazone.com/api/file-upload/file", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUploadedFileUrl(result.url);
      });
  };

  const [imageUploadLoader, setImageUploadLoader] = useState(false);

  return (
    <div className="containerAdCategory">
      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate("/premiumplans")}>
          <img src={chevLeft} alt="back" />
        </button>

        <h1 className="heading-font"> Create Plan</h1>
      </div>

      <ToastContainer />

      <Formik
        validationSchema={schema}
        initialValues={{
          name: "",
          description: "",
          duration: "",
          currency: "",
          price: "",
        }}
        onSubmit={(values) => {
          addCategory({
            name: values.name,
            description: values.description,
            duration: String(values.duration),
            currency: values.currency,
            price: values.price,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className={styles.addCategory}>
            <Upload
              onDrop={(files) => onFileDrop(files)}
              imageUploadLoader={imageUploadLoader}
            />

            <p className="red">{imageError && "Image is required "}</p>

            <form noValidate onSubmit={handleSubmit}>
              <div className={styles.catName}>
                <label>Plan Name</label>
                <input
                  type="name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  id="planName"
                  placeholder="Enter plan name"
                />

                <p className="red">
                  {errors.name && touched.name && errors.name}
                </p>
              </div>

              <div className={styles.catName}>
                <label>Description</label>
                <textarea
                  type="name"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  id="description"
                  placeholder="Enter description"
                />

                <p className="red">
                  {errors.description &&
                    touched.description &&
                    errors.description}
                </p>
              </div>

              <div className={styles.catName}>
                <label>Duration</label>

                <div className={styles.row}>
                  <input
                    type="number"
                    min="0"
                    name="duration"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.duration}
                    placeholder="Enter number"
                  />

                  <Dropdown
                    options={durationOptions}
                    onChange={(item) => onDurationSelect(item)}
                    value={values.currency}
                    placeholder="Select duration"
                    className="dropdown"
                    placeholderClassName="myPlaceholderClassName"
                    controlClassName="myControlClassName"
                    menuClassName="myMenuClassName"
                    arrowClassName="myArrowClassName"
                  />
                </div>

                <p className="red">
                  {errors.duration && touched.duration && errors.duration}
                </p>
              </div>

              <div className={styles.catName}>
                <label>Currency & Price</label>

                <div className={styles.priceAndCurrency}>
                  <Dropdown
                    options={currencyOptions}
                    onChange={(item) => onCurrencySelect(item)}
                    value={currencyOptions[1]}
                    placeholder="Select currency"
                    className="dropdown2"
                    placeholderClassName="myPlaceholderClassName"
                    controlClassName="myControlClassName"
                    menuClassName="myMenuClassName"
                    arrowClassName="myArrowClassName"
                  />
                  <input
                    className={styles.inputCurrency}
                    type="price"
                    name="price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    placeholder="Enter plan price"
                  />
                </div>
                <p className="red">
                  {errors.price && touched.price && errors.price}
                </p>
              </div>

              <div className={styles.actionButtons}>
                <button className="btn-save" type="submit" ref={btnRef}>
                  {!saveLoad ? (
                    <>
                      <img src={tick} alt="save" /> Save
                    </>
                  ) : (
                    <ScaleLoader color="white" />
                  )}
                </button>
                <button
                  className="btn-cancel"
                  onClick={() => navigate("/premiumplans")}
                >
                  <img src={cross} alt="cancel" />
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddPlan;
