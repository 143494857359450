import React, { useState } from "react";
import ConfirmRemoveModal from "./ConfirmRemoveModal";
import styles from "../../styles/adDetail.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper";
import alert from "../../assets/icons/alert.png";
import cross from "../../assets/icons/cancel2.png";
import premiumStar from "../../assets/icons/premiumStar.png";
import activeSymbol from "../../assets/icons/activeSymbol.png";
import { useSelector } from "react-redux";
import ConfirmActivateModal from "./ConfirmActivateModal";
import ConfirmApproveModal from "./ConfirmApproveAd";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { useNavigate } from "react-router-dom";
import chevLeft from "../../assets/icons/chevleft.png";
import alt from "../../assets/images/gallery.svg";
import alt2 from "../../assets/images/user.svg";
import { Markup } from "interweave";
import moment from "moment/moment";

const AdIndividualNew = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openConfirmActivate, setOpenConfirmActivate] = useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigate = useNavigate();

  const notifySuccess = () =>
    toast.success("Ad updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const selectedObject = useSelector((state) => state.ads.selectedAdObject);

  const onImageError = (ev) => {
    ev.target.src = alt;
  };

  const onImageError2 = (ev) => {
    ev.target.src = alt2;
  };
  const desc = selectedObject?.adDesc;

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const checkAdStatus = () => {
    if (selectedObject?.isAdSold) {
      return <h2 className={styles.deactivatedStatus}>Sold</h2>;
    }

    if (!selectedObject?.adStatus && !selectedObject?.isAdSold) {
      return <h2 className={styles.deactivatedStatus}>Deactivated</h2>;
    }

    if (selectedObject?.adStatus && !selectedObject?.isAdSold) {
      return <h2 className={styles.activeStatus}>Active</h2>;
    }
  };

  return (
    <div className="containerAdCategory">
      <ConfirmRemoveModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        notifySuccess={notifySuccess}
      />
      <ConfirmActivateModal
        openConfirmActivate={openConfirmActivate}
        setOpenConfirmActivate={setOpenConfirmActivate}
        notifySuccess={notifySuccess}
      />
      <ConfirmApproveModal
        openConfirmApprove={openConfirmApprove}
        setOpenConfirmApprove={setOpenConfirmApprove}
        notifySuccess={notifySuccess}
      />

      <ConfirmDeleteModal
        openConfirmDelete={openConfirmDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
        notifySuccess={notifySuccess}
      />

      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate(-1)}>
          <img src={chevLeft} alt="back" />
        </button>
        <h1 className="s20"># {selectedObject?._id}</h1>
        <ToastContainer />
      </div>
      <div className={styles.adDetail}>
        <div className={styles.swiperAndStats}>
          <div className={styles.swiper}>
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs, Pagination]}
              className="mySwiper2"
              pagination={{
                clickable: true,
              }}
            >
              {selectedObject?.adImage?.map((item) => (
                <SwiperSlide>
                  <img
                    onError={onImageError}
                    width="100%"
                    height="100%"
                    src={item}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={false}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
            >
              {selectedObject?.adImage?.map((item) => (
                <SwiperSlide>
                  <img
                    onError={onImageError}
                    width="100%"
                    height="100%"
                    src={item}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className={styles.statCards}>
            <div className={styles.statCard}>
              <h3>Impressions</h3>
              <h2>{String(selectedObject?.adImpressions).slice(0, 5)}</h2>
            </div>
            <div className={styles.statCard}>
              <h3>Click through rate</h3>
              <h2>{selectedObject?.adClickThroughRate}</h2>
            </div>

            <div className={styles.statCard}>
              <h3>Saved</h3>
              <h2>{selectedObject?.adNumberTimeSaved}</h2>
            </div>
          </div>
        </div>

        <div className={styles.adDetailed}>
          <div className={styles.status}>
            <div className={styles.activeAndPremium}>
              <div className={styles.activeIndicator}>
                <img src={activeSymbol} />

                {/* {!selectedObject?.adStatus ? (
                  <h2 className={styles.deactivatedStatus}>Deactivated</h2>
                ) : (
                  <h2 className={styles.activeStatus}>Active</h2>
                )} */}

                {checkAdStatus()}
              </div>
              {selectedObject?.adIsPremium && (
                <div className={styles.premiumIndicator}>
                  <img src={premiumStar} />
                  <h2 className={styles.premiumStatus}>Premium</h2>
                </div>
              )}
            </div>

            <h2 className={styles.activeSince}>
              Posted on{" "}
              <span className={"red"}>
                {" "}
                {moment(selectedObject?.createdAt).format("DD MMM YYYY")}
              </span>
            </h2>
          </div>

          <div className={styles.category}>
            <h2>
              Category :{" "}
              <span className={"red"}>{selectedObject?.adCategory?.title}</span>{" "}
            </h2>
          </div>

          <div className={styles.adTitle}>
            <h1>{selectedObject?.adName}</h1>
            <h2 className={styles.price}>${selectedObject?.adPrice}</h2>
            <div className={styles.sellerDetails}>
              <h3 className={styles.seller}>Seller :</h3>
              <img
                onError={onImageError2}
                src={selectedObject?.userId?.uploadImage}
              />
              <h2>
                {capitalizeName(
                  selectedObject?.userId?.firstName +
                    " " +
                    selectedObject?.userId?.lastName
                )}
              </h2>
            </div>
            <h2 className={styles.desc}>Description</h2>
            <p className={styles.about}>
              <Markup content={desc} />
            </p>
            <div className={styles.location}>
              <h2 className={styles.locationText}>Location :</h2>
              <p className="">{`${selectedObject?.adLocation?.city} ,  ${selectedObject?.adLocation?.address}`}</p>
            </div>

            {selectedObject?.adIsApproved && selectedObject?.adStatus && (
              <div className={styles.actionButtons}>
                <button className="btn-save" onClick={() => setOpenEdit(true)}>
                  <img src={alert} alt="save" />
                  Deactivate
                </button>
                <button
                  className="btn-cancel"
                  onClick={() => setOpenConfirmDelete(true)}
                >
                  <img src={cross} alt="cancel" />
                  Delete
                </button>
              </div>
            )}
            {!selectedObject?.adStatus && selectedObject?.adIsApproved && (
              <>
                <>
                  <h2 className={styles.desc}>Reason for rejection</h2>
                  <p className={styles.about}>{selectedObject?.adRemark}</p>
                </>
                <div className={styles.actionButtons}>
                  <button
                    className="btn-save"
                    onClick={() => setOpenConfirmActivate(true)}
                  >
                    <img src={alert} alt="save" />
                    Activate
                  </button>
                  <button
                    className="btn-cancel"
                    onClick={() => setOpenConfirmDelete(true)}
                  >
                    <img src={cross} alt="cancel" />
                    Remove
                  </button>
                </div>
              </>
            )}
            {!selectedObject?.adIsApproved && (
              <div className={styles.actionButtons}>
                <button
                  className="btn-save"
                  onClick={() => setOpenConfirmApprove(true)}
                >
                  <img src={alert} alt="save" />
                  Approve
                </button>
                <button
                  className="btn-cancel"
                  onClick={() => setOpenEdit(true)}
                >
                  <img src={cross} alt="cancel" />
                  Reject
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedObject?.remarksCount > 0 && (
        <div className={styles.reportsTabContainer}>
          <h2 className="red"> Reported by</h2>

          <div className={styles.reportsList}>
            {selectedObject.remarks.map((item, index) => (
              <div className={styles.remarkCard}>
                <div className={styles.reportHeader}>
                  <h3>
                    {capitalizeName(
                      item.userId?.firstName + " " + item.userId?.lastName
                    )}
                    , {moment(item.createdAt).format("MM-DD-YYYY")}
                  </h3>
                </div>
                <div className={styles.reportContent}>
                  <p>"{item.remark}"</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdIndividualNew;
