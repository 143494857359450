import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const intitialTransactionsState = {
  allTransactions: [],
  status: "idle",
  selectedTransactionsId: "",
  selectedTransactionObject: {},
  transactionStatus: "idle",
  transactionById: {},
  getTransactionsReponse: "",
  getTransactionById: {},
  getTransactionByIdResponse: "idle",
};

export const fetchAllTransactions = createAsyncThunk(
  "transactions/fetchAllTransactions",
  async () => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      "https://api.angolazone.com/api/Order/getTransactions",
      requestOptions
    ).then((res) => res.json());
  }
);

export const getTransactionById = createAsyncThunk(
  "transactions/getTransactionById",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.angolazone.com/api/Order/getTransactions/${id}`,
      requestOptions
    ).then((res) => res.json());
  }
);

export const getTransactionById2 = createAsyncThunk(
  "transactions/getTransactionById2",
  async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.angolazone.com/api/Order/getTransactions?_id=${id}`,
      requestOptions
    ).then((res) => res.json());
  }
);

const transactionsSlice = createSlice({
  name: "transactionsSlice",
  initialState: intitialTransactionsState,
  reducers: {
    setSelectedTransactionId(state, action) {
      state.selectedTransactionsId = action.payload;
      state.selectedTransactionObject = state.allTransactions.filter(
        (item) => (item.adiId = action.payload)
      );
    },
    setSelectedTransactionObject(state, action) {
      state.selectedTransactionObject = action.payload;
    },
  },
  extraReducers: {
    [fetchAllTransactions.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAllTransactions.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.allTransactions = action.payload.transactions;
      state.getTransactionsReponse = action.payload;
    },
    [fetchAllTransactions.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getTransactionById.pending]: (state, action) => {
      state.getTransactionsReponse = "loading";
    },
    [getTransactionById.fulfilled]: (state, action) => {
      state.transactionStatus = "succeeded";
      state.transactionById = action.payload.transaction;
    },
    [getTransactionById.rejected]: (state, action) => {
      state.getTransactionsReponse = "failed";
    },

    [getTransactionById2.pending]: (state, action) => {
      state.getTransactionByIdResponse = "loading";
    },
    [getTransactionById2.fulfilled]: (state, action) => {
      state.getTransactionByIdResponse = "succeeded";
      state.selectedTransactionObject = action.payload.transactions;
    },
    [getTransactionById2.rejected]: (state, action) => {
      state.getTransactionByIdResponse = "failed";
    },
  },
});

export const transactionActions = transactionsSlice.actions;

export default transactionsSlice;
