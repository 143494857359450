import React, { useState } from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/trashModal.svg";
import styles from "../../styles/adDetail.module.css";
import cross2 from "../../assets/icons/cancel2.png";
import alert from "../../assets/icons/saveTick.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllUsers } from "../../store/users";
import close from "../../assets/icons/cancelCross.png";
import { ScaleLoader } from "react-spinners";
import Cookies from "js-cookie";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const ConfirmDeleteCategoryModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  id,
  notifySuccess,
}) => {
  const [deleteLoad, setDeleteLoad] = useState(false);
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const onDeleteClick = () => {
    setDeleteLoad(true);

    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      active: false,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/user/delete/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          dispatch(fetchAllUsers());
          setOpenDeleteModal(false);
          setDeleteLoad(false);

          notifySuccess();
          setTimeout(() => {
            navigate("/users/blockedUsers");
          }, 3000);
        }

        if (result.error?.code === "auth/id-token-expired") {
          setOpenDeleteModal(false);
          setDeleteLoad(false);
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
      });
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };
  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openDeleteModal}
      shouldCloseOnEsc
      onRequestClose={() => setOpenDeleteModal(false)}
      style={bg}
    >
      <div className={styles.removeAd}>
        <img
          className={styles.cancelCross}
          src={close}
          alt="X"
          onClick={() => setOpenDeleteModal(false)}
        />
        <img className="modalDelete" alt="Delete" src={confirmRemove} />
        <h1>Are you sure want to remove this user?</h1>
        <div className={styles.actionButtons}>
          <button
            onClick={() => onDeleteClick()}
            className="btn-save"
            disabled={deleteLoad ? true : false}
          >
            {!deleteLoad ? (
              <>
                <img src={alert} alt="save" />
                Remove
              </>
            ) : (
              <ScaleLoader color="white" />
            )}
          </button>
          <button
            className="btn-cancel"
            onClick={() => setOpenDeleteModal(false)}
          >
            <img src={cross2} alt="cancel" />
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteCategoryModal;
