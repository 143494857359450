import React, { useEffect } from "react";
import RouteEnum from "./RouteEnum";
import "./styles/global.css";
import { auth } from "./firebase";
import Cookies from "js-cookie";
import Loading from "./components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/auth";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          authActions.loginUser({
            uid: authUser.uid,
            email: authUser.email,
          })
        );
        Cookies.set("uid", authUser.uid);
        dispatch(authActions.setLoading(false));
        dispatch(authActions.setAuthentication(true));
      } else {
        dispatch(authActions.setLoading(false));
      }
    });
  }, [dispatch]);

  const loading2 = useSelector((state) => state.auth.isLoading);

  return <>{loading2 ? <Loading /> : <RouteEnum />}</>;
}

export default App;
