import React, { useState } from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/trashModal.svg";
import styles from "../../styles/adDetail.module.css";
import cross from "../../assets/icons/cancelCross.png";
import cross2 from "../../assets/icons/cancel2.png";
import alert from "../../assets/icons/saveTick.png";
import { useDispatch } from "react-redux";
import { fetchAllCategories } from "../../store/categories";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { useRef } from "react";
import Cookies from "js-cookie";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const ConfirmDeleteCategoryModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  id,
  notifySuccess,
  notifyError,
}) => {
  const [deleteLoad, setDeleteLoad] = useState(false);
  const token = Cookies.get("token");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let btnRef = useRef();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const onDeleteClick = async () => {
    setDeleteLoad(true);
    btnRef.current.setAttribute("disabled", "disabled");

    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `https://api.angolazone.com/api/${id}`,
        requestOptions
      );

      const response = await res.json();

      if (response.status === true) {
        setDeleteLoad(false);
        dispatch(fetchAllCategories());
        notifySuccess();
        setOpenDeleteModal(false);

        setTimeout(() => {
          navigate("/categories");
        }, 1000);
      }
    } catch (error) {
      if (error.error?.code === "auth/id-token-expired") {
        setOpenDeleteModal(false);
        dispatch(authActions.setTokenError(true));
        sessionExpireLogin();
        setDeleteLoad(false);
      }
    }
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openDeleteModal}
      shouldCloseOnEsc
      onRequestClose={() => setOpenDeleteModal(false)}
      style={bg}
    >
      <div className={styles.removeAd}>
        <img
          className={styles.cancelCross}
          src={cross}
          alt="X"
          onClick={() => setOpenDeleteModal(false)}
        />
        <img className="modalDelete" alt="Delete" src={confirmRemove} />
        <h1>Are you sure want to remove this category?</h1>
        <div className={styles.actionButtons}>
          <button
            ref={btnRef}
            onClick={() => onDeleteClick()}
            className="btn-save"
          >
            {!deleteLoad ? (
              <>
                <img src={alert} alt="save" />
                Remove
              </>
            ) : (
              <ScaleLoader color="white" />
            )}
          </button>
          <button
            className="btn-cancel"
            onClick={() => setOpenDeleteModal(false)}
          >
            <img src={cross2} alt="cancel" />
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteCategoryModal;
