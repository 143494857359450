import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import chevRightRed from "../../assets/icons/chevRight.png";
import chevLeftRed from "../../assets/icons/chevLeftRed.png";
import styles from "../Categories Table/cat.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import trash from "../../assets/icons/trash2.png";
import eye from "../../assets/icons/EyeNew.png";
import tick from "../../assets/icons/whiteTick.png";
import clock from "../../assets/icons/whiteClock.png";
import alt from "../../assets/images/gallery.svg";
import moment from "moment";
import plus from "../../assets/icons/plus.png";
import { bannerActions } from "../../store/banners";

const BannerTable = ({ tableData, setOpenDeleteModal }) => {
  const navigate = useNavigate();
  const data = useMemo(() => tableData, []);
  const dispatch = useDispatch();

  const onImageError = (ev) => {
    ev.target.src = alt;
  };

  const onRowClick = (id) => {
    dispatch(bannerActions.setSelectedBannerId(id));
    navigate(`/banners/updateBanner/:${id}`);
  };

  const onEditAdClick = (id) => {
    navigate(`/banners/updateBanner/:${id}`);
    dispatch(bannerActions.setSelectedBannerId(id));
  };

  const onDeleteAdClick = (id) => {
    setOpenDeleteModal(true);
    dispatch(bannerActions.setSelectedBannerId(id));
  };

  const checkStatus = (date1, date2, active) => {
    let dateNum2 = new Date(date2);
    let dateNum1 = new Date(date1);

    let diff = new Date().getTime() - dateNum1.getTime();

    let diff2 = new Date().getTime() - dateNum2.getTime();

    if (diff2 > 0 && diff && active === true) {
      return (
        <p className="bannerDisabled">
          <img src={clock} />
          Expired
        </p>
      );
    } else if (diff2 < 0 && active === true) {
      return (
        <p className="bannerActive">
          <img src={tick} />
          Active
        </p>
      );
    } else if (active === false) {
      return <p className="bannerDisabled"> Disabled</p>;
    }
  };

  const COLUMNS_ADSTABLE = [
    {
      Header: () => <p className={styles.headerID}>Banner ID</p>,
      accessor: "_id",
      Cell: (tableProps) => (
        <p className={styles.id}>
          #{String(tableProps.row.original._id).slice(-4)}
        </p>
      ),
    },
    {
      Header: "Image",
      accessor: "bannerImage",
      Cell: (tableProps) => (
        <div className="bannerImgContainer">
          <img
            onError={onImageError}
            className="bannerImage2"
            alt="Banner"
            src={tableProps.row.original.bannerImage}
          />
        </div>
      ),
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: (tableProps) => (
        <button
          className="btn-noBorder"
          onClick={() => onRowClick(tableProps.row.original._id)}
        >
          {tableProps.row.original.title}
        </button>
      ),
    },

    {
      Header: "Duration",
      accessor: "price",
      Cell: (tableProps) => (
        <p className="red">
          {moment(tableProps.row.original.startDate).format("DD MMM YYYY") +
            "-" +
            moment(tableProps.row.original.endDate).format("DD MMM YYYY")}
        </p>
      ),
    },

    {
      Header: "Status",
      accessor: "sellerName",
      Cell: (tableProps) => (
        <div>
          {checkStatus(
            tableProps.row.original.startDate,
            tableProps.row.original.endDate,
            tableProps.row.original.active
          )}
        </div>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-delete2"
            onClick={() => onEditAdClick(tableProps.row.original._id)}
          >
            <img src={eye} alt="View" />
          </button>

          <button
            className="btn-delete"
            onClick={() => onDeleteAdClick(tableProps.row.original._id)}
          >
            <img src={trash} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_ADSTABLE, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => {
    //     return [
    //       {
    //         id: "selection",
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <Checkbox {...getToggleAllRowsSelectedProps()} />
    //         ),
    //         Cell: ({ row }) => (
    //           <Checkbox {...row.getToggleRowSelectedProps()} />
    //         ),
    //       },
    //       ...columns,
    //     ];
    //   });
    // }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Banners </h1>

        <div className="filters">
          <NavLink to={"/banners/addBanner"} className="NAV">
            <button
              onClick={() => navigate("/banners/addBanner")}
              className={"addNewButton"}
            >
              <img className="addIcon" src={plus} alt="+" />
              Add Banner
            </button>
          </NavLink>

          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>
      <ToastContainer />

      {tableData.length === 0 ? (
        <h1>No Banners at the moment</h1>
      ) : (
        <>
          <div className="tableCont">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            <div className="pageInfo">
              {"\u00a0"} Go to page number : {"\u00a0"}
              <input
                type="number"
                default={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </div>
            <div className="buttons">
              <button
                className="jumpButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {" "}
                Go to first page
              </button>
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              {"\u00a0"}
              {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              <button
                className="jumpButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Go to last page
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BannerTable;
