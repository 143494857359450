import React, { useState } from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/UserBig.png";
import styles from "../../styles/adDetail.module.css";
import cross from "../../assets/icons/cancelCross.png";
import cross2 from "../../assets/icons/cancel2.png";
import alert from "../../assets/icons/alert.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllUsers } from "../../store/users";
import { ScaleLoader } from "react-spinners";
import Cookies from "js-cookie";
import { sessionExpireLogin } from "../../firebase";
import { authActions } from "../../store/auth";

const ConfirmDeleteCategoryModal = ({
  openDeactivateModal,
  setOpenDeactivateModal,
  id,
  notifySuccess,
  notifyError,
}) => {
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [deleteLoad, setDeleteLoad] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const onDeleteClick = () => {
    setDeleteLoad(true);

    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      active: false,
      comment: reason,
      suspensionDate: new Date(),
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/admin/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          dispatch(fetchAllUsers());
          setOpenDeactivateModal(false);
          notifySuccess();
          setTimeout(() => {
            navigate("/users/blockedUsers");
          }, 3000);
          setDeleteLoad(false);
        }

        if (result.error?.code === "auth/id-token-expired") {
          setOpenDeactivateModal(false);
          setDeleteLoad(false);
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
      });
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };
  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openDeactivateModal}
      shouldCloseOnEsc
      onRequestClose={() => setOpenDeactivateModal(false)}
      style={bg}
    >
      <div className={styles.removeAd}>
        <img
          className={styles.cancelCross}
          src={cross}
          alt="X"
          onClick={() => setOpenDeactivateModal(false)}
        />
        <img className="modalDelete" alt="Delete" src={confirmRemove} />
        <h1>Please provide a reason before deactivating this user</h1>
        <textarea
          onChange={(e) => setReason(e.target.value)}
          className="reason"
        ></textarea>

        <div className={styles.actionButtons}>
          <button
            onClick={() => onDeleteClick()}
            className="btn-save"
            disabled={deleteLoad ? true : false}
          >
            {!deleteLoad ? (
              <>
                <img src={alert} alt="save" />
                Deactivate user
              </>
            ) : (
              <ScaleLoader color="white" />
            )}
          </button>
          <button
            className="btn-cancel"
            onClick={() => setOpenDeactivateModal(false)}
          >
            <img src={cross2} alt="cancel" />
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteCategoryModal;
