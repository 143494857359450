import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { categoryActions } from "../../store/categories";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "../../styles/addCategory.module.css";
import { GlobalFilter } from "./GlobalFilter_Category";
import chevRightRed from "../../assets/icons/chevRight.png";
import chevLeftRed from "../../assets/icons/chevLeftRed.png";
import trash from "../../assets/icons/trash2.png";
import edit from "../../assets/icons/EyeNew.png";
import plus from "../../assets/icons/plus.png";
import userAlt from "../../assets/images/gallery.svg";

import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { useSelector, useDispatch } from "react-redux";
import ConfirmDeleteCategoryModal from "./ConfirmDeleteCategory";

const CategoriesTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data2 = useSelector((state) => state.categories.allCategories);
  const [selectedID, setSelectedID] = useState("");

  const allCategoryData = data2?.map((data, index) => {
    return {
      id: index + 1,
      image: data.image,
      title: data.title,
      desc: data.desc,
      status: data.active,
      _id: data._id,
      key: data.id,
    };
  });

  const data = useMemo(() => allCategoryData, []);

  const notifySuccess = () =>
    toast.success("Category deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onEditCategoryClick = (id) => {
    dispatch(categoryActions.setSelectedCatId(id));
    navigate(`/categories/updateCategory/:${id}`);
  };

  const onDeleteCategoryClick = (id) => {
    setOpenDeleteModal(true);
    setSelectedID(id);
  };

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  const COLUMNS_CATEGORY2 = [
    {
      Header: () => <p className="headerID">Category ID</p>,
      accessor: "id",
      Cell: (tableProps) => (
        <p className="id">{String(tableProps.row.original._id).slice(-4)}</p>
      ),
    },
    {
      Header: () => <p className="d-none">status</p>,
      accessor: "active",
      Cell: (tableProps) => (
        <p className="">
          {/* {!tableProps.row.original.active ? "active" : "disabled"} */}
        </p>
      ),
    },
    {
      Header: "Image",
      accessor: "image",
      Cell: (tableProps) => {
        return (
          <div className="adsImageContainer">
            <img
              onError={onImageError}
              className="adsImage"
              alt="Img"
              src={tableProps.row.original.image}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "title",

      Cell: (tableProps) => (
        <button
          onClick={() => onEditCategoryClick(tableProps.row.original._id)}
          className="btn-noBorder red"
        >
          {tableProps.row.original.title}
        </button>
      ),
    },

    {
      Header: <div className={styles.headDesc}>Description</div>,
      accessor: "desc",
      Cell: (tableProps) => (
        <p className={styles.para}>{tableProps.row.original.desc}</p>
      ),
    },

    {
      Header: <div className={styles.headDesc}>Status</div>,
      accessor: "status",
      Cell: (tableProps) => (
        <>
          {tableProps.row.original.status ? (
            <h2 className={styles.activeStatus}>Active</h2>
          ) : (
            <h2 className={styles.deactivatedStatus2}>Disabled</h2>
          )}
        </>
      ),
    },
    {
      Header: () => <p className={styles.headerID}>Action</p>,
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-delete2"
            onClick={() => onEditCategoryClick(tableProps.row.original._id)}
          >
            <img src={edit} alt={edit} />
          </button>

          <button
            className="btn-delete"
            onClick={() => onDeleteCategoryClick(tableProps.row.original._id)}
          >
            <img src={trash} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_CATEGORY2, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => {
    //     return [
    //       {
    //         id: "selection",
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <Checkbox {...getToggleAllRowsSelectedProps()} />
    //         ),
    //         Cell: ({ row }) => (
    //           <Checkbox {...row.getToggleRowSelectedProps()} />
    //         ),
    //       },
    //       ...columns,
    //     ];
    //   });
    // }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1> Categories </h1>

        <div className="filters">
          <NavLink to={"/categories/addCategory"} className="NAV">
            <button className={styles.addNewButton}>
              <img className="addIcon" src={plus} alt="+" />
              Add Category
            </button>
          </NavLink>

          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>

      <ToastContainer />

      <ConfirmDeleteCategoryModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        id={selectedID}
        notifySuccess={notifySuccess}
        notifyError={notifyError}
      />

      {allCategoryData.length === 0 ? (
        <h1>No Categories at the moment</h1>
      ) : (
        <>
          <div className="tableCont">
            <table className="catTable" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className={styles.rowH}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="tableFooters">
            <div className="pageInfo">
              {"\u00a0"} Go to page number : {"\u00a0"}
              <input
                type="number"
                default={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </div>
            <div className="buttons">
              <button
                className="jumpButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {" "}
                Go to first page
              </button>
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} alt="Back" />
              </button>
              {"\u00a0"}
              {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} alt="Next" />
              </button>
              <button
                className="jumpButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Go to last page
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoriesTable;
