import React, { useEffect } from "react";
import { fetchAllCategories } from "../../store/categories";
import { useDispatch, useSelector } from "react-redux";
import CategoriesTable from "../../components/Categories Table/CategoriesTable";
import { ScaleLoader } from "react-spinners";

const Categories = () => {
  const dispatch = useDispatch();
  const allFetchStatus = useSelector((state) => state.categories.status);
  const fetchByIdStatus = useSelector((state) => state.categories.status);

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  return (
    <>
      {allFetchStatus === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
            <p className="fetchText"> Fetching Categories.. </p>
          </div>
        </div>
      ) : (
        <CategoriesTable />
      )}
    </>
  );
};

export default Categories;
