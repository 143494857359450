import React, { useEffect } from "react";
import { fetchAllAds } from "../../store/ads";
import ActiveAds from "./ActiveAds";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import Cookies from "js-cookie";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const Ads = () => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state.ads.status);
  const fetchStatus = useSelector((state) => state.ads.getAdsResponse);

  const sessionToken = Cookies.get("token");
  useEffect(() => {
    dispatch(
      fetchAllAds({
        token: sessionToken,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (fetchStatus?.error?.code === "auth/id-token-expired") {
      dispatch(authActions.setTokenError(true));
      sessionExpireLogin();
    }
  }, [fetchStatus]);

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
            <p className="fetchText"> Fetching latest ad list.. </p>
          </div>
        </div>
      ) : (
        <ActiveAds />
      )}
    </>
  );
};

export default Ads;
