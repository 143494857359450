import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { GlobalFilter } from "./GlobalFilter_Users";
import chevRightRed from "../../assets/icons/chevRight.png";
import chevLeftRed from "../../assets/icons/chevLeftRed.png";
import styles from "../Categories Table/cat.module.css";
import trash from "../../assets/icons/trash2.png";
import eye from "../../assets/icons/EyeNew.png";
import { userActions } from "../../store/users";
import { useDispatch } from "react-redux";
import userAlt from "../../assets/images/user.svg";
import Cookies from "js-cookie";

const UserTable = ({ tableData, type, setOpenDeleteModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onEditAdClick = (id) => {
    dispatch(userActions.setSelectedUser(id));
    if (type === "Active users") {
      navigate(`/users/activeUsers/user/:${id}`);
    }
    if (type === "Blocked users") {
      navigate(`/users/blockedUsers/user/:${id}`);
    }
  };

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  const onDeleteAdClick = (id) => {
    setOpenDeleteModal(true);
    dispatch(userActions.setSelectedUser(id));
  };

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const COLUMNS_USERTABLE = [
    {
      Header: () => <p className={styles.headerID}>User ID</p>,
      accessor: "id",
      Cell: (tableProps) => (
        <p className={styles.id}>
          #{String(tableProps.row.original.id).slice(-4)}
        </p>
      ),
    },
    {
      Header: "Image",
      accessor: "uploadImage",
      Cell: (tableProps) => (
        <div className="adsImageContainer">
          <img
            className="userImage"
            src={tableProps.row.original.uploadImage}
            alt={"Profile Pic"}
            onError={onImageError}
          />
        </div>
      ),
    },
    {
      Header: "User name",
      accessor: "firstName",
      Cell: (tableProps) => (
        <p
          onClick={() => onEditAdClick(tableProps.row.original.id)}
          className="red userName"
        >
          {capitalizeName(
            tableProps.row.original.firstName +
              " " +
              tableProps.row.original.lastName
          )}
        </p>
      ),
    },

    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      Cell: (tableProps) => <p>+{tableProps.row.original.phoneNumber}</p>,
    },
    {
      Header: "Email",
      accessor: "email",
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-delete2"
            onClick={() => onEditAdClick(tableProps.row.original.id)}
          >
            <img src={eye} alt="View" />
          </button>

          <button
            className="btn-delete"
            onClick={() => onDeleteAdClick(tableProps.row.original.id)}
          >
            <img src={trash} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_USERTABLE, []);
  const data = useMemo(() => tableData, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => {
    //     return [
    //       {
    //         id: "selection",
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <Checkbox {...getToggleAllRowsSelectedProps()} />
    //         ),
    //         Cell: ({ row }) => (
    //           <Checkbox {...row.getToggleRowSelectedProps()} />
    //         ),
    //       },
    //       ...columns,
    //     ];
    //   });
    // }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      {tableData.length === 0 ? (
        <div className="heading-container">
          <h1 className="noData">No users at the moment.</h1>
        </div>
      ) : (
        <>
          <div className="heading-container">
            <h1>{type} </h1>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>

          <div className="tableCont">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            <div className="pageInfo">
              {"\u00a0"} Go to page number : {"\u00a0"}
              <input
                type="number"
                default={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </div>
            <div className="buttons">
              <button
                className="jumpButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {" "}
                Go to first page
              </button>
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              {"\u00a0"}
              {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              <button
                className="jumpButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Go to last page
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserTable;
