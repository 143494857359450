import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialAuthState = {
  isAuthenticated: false,
  sessionToken: "",
  userId: "",
  loginError: false,
  tokenError: false,
  user: null,
  isLoading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setFirebaseToken(state, action) {
      state.firebaseToken = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    setSessionToken(state, action) {
      state.sessionToken = action.payload;
      Cookies.set("token", action.payload);
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setLoginError(state, action) {
      state.loginError = action.payload;
    },
    setAuthentication(state, action) {
      state.isAuthenticated = action.payload;
    },
    setTokenError(state, action) {
      state.tokenError = action.payload;
    },

    loginUser: (state, action) => {
      state.user = action.payload;
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
export const authActions = authSlice.actions;

export const getSessionToken = (id) => {
  return async (dispatch) => {
    await axios
      .post(`https://api.angolazone.com/api/uid-token?uid=${id}`)
      .then((res) => {
        Cookies.set("token", res.data.token);
        // Cookies.set("isAuthenticated", true);
        dispatch(authActions.setSessionToken(res.data.token));
      });
  };
};

// export const setLoader2 = (dispatch, value) => {
//   dispatch(authActions.setLoader(value));
// };

export default authSlice;
