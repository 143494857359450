import React, { useState } from "react";
import UsersTable from "../../components/User Table/UsersTable";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import ComfirmDeleteUser from "./ConfirmDeleteUser";

const BlockedUsers = () => {
  const blockedUsers = useSelector((state) => state.users.blockedUsers);
  const status = useSelector((state) => state.users.userFetchStatus);

  const id = useSelector((state) => state.users.selectedUserId);

  const notifySuccess = () =>
    toast.success("User deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      {status === "loading" || status === "idle" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
            <p className="fetchText"> Fetching user list.. </p>
          </div>
        </div>
      ) : (
        <>
          <UsersTable
            tableData={blockedUsers}
            type={"Blocked users"}
            setOpenDeleteModal={setOpenDeleteModal}
          />
          <ComfirmDeleteUser
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            notifySuccess={notifySuccess}
            notifyError={notifyError}
            id={id}
          />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default BlockedUsers;
