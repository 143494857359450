import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const dashboardInitialState = {
  dashboardStats: {},
  dashboardStatsFetchStatus: "idle",
};

export const fetchStats = createAsyncThunk("dashboard/fetchStats", async () => {
  var myHeaders = new Headers();
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    "https://api.angolazone.com/api/admin/getAllStats",
    requestOptions
  ).then((response) => response.json());
});

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: dashboardInitialState,
  reducers: {
    setDashboardStats(state, action) {
      state.dashboardStats = action.payload;
    },
  },

  extraReducers: {
    [fetchStats.pending]: (state, action) => {
      state.dashboardStatsFetchStatus = "loading";
    },
    [fetchStats.fulfilled]: (state, { payload }) => {
      state.dashboardStatsFetchStatus = "succeeded";
      state.dashboardStats = payload;
    },
    [fetchStats.rejected]: (state, action) => {
      state.dashboardStatsFetchStatus = "failed";
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
