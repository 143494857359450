import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchAllCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    return fetch("https://api.angolazone.com/api/all-category").then((res) =>
      res.json()
    );
  }
);

export const getCategoryById = createAsyncThunk(
  "categories/getCategoryById",
  async (id) => {
    return fetch(`https://api.angolazone.com/api/all-category?_id=${id}`).then(
      (res) => res.json()
    );
  }
);

const initialCategoriesState = {
  selectedCatId: "",
  selectedCatObject: {},
  allCategories: [],
  status: "idle",
  activeCategories: [],
  getCategoryById: {},
  getCategoryByIdResponse: "idle",
};

const categorySlice = createSlice({
  name: "categorySlice",
  initialState: initialCategoriesState,
  reducers: {
    setSelectedCatId(state, action) {
      state.selectedCatId = action.payload;
      state.selectedCatObject = state.allCategories?.filter((item) => {
        if (item._id === state.selectedCatId) {
          return [
            {
              id: item._id,
              image: item.image,
              title: item.title,
              desc: item.desc,
              status: item.active,
              _id: item._id,
              key: item.id,
            },
          ];
        }
      });
    },
    setAllCategories(state, action) {
      state.allCategories = action.payload;
    },
    setActiveCategories(state, action) {
      state.activeCategories = action.payload;
    },
  },
  extraReducers: {
    [fetchAllCategories.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAllCategories.fulfilled]: (state, { payload }) => {
      state.status = "succeeded";
      state.allCategories = payload.category;
    },

    [fetchAllCategories.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getCategoryById.pending]: (state, action) => {
      state.getCategoryByIdResponse = "loading";
    },
    [getCategoryById.fulfilled]: (state, { payload }) => {
      state.getCategoryByIdResponse = "succeeded";
      state.getCategoryById = payload.category;
      state.selectedCatObject = payload.category;
    },

    [getCategoryById.rejected]: (state, action) => {
      state.getCategoryByIdResponse = "failed";
    },
  },
});

export const deleteCategory = (body) => {
  var myHeaders = new Headers();
  myHeaders.append("firebasetoken", body.token);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "DETETE",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(`https://api.angolazone.com/api/${body.id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {});
};

export const addCategory = (body) => {
  var myHeaders = new Headers();
  myHeaders.append("firebasetoken", body.token);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    title: body.categoryName,
    image:
      "https://classroom-training-bucket.s3.amazonaws.com/blockveir-image.jpg",
    price: "100",
    active: "true",
    isDeleted: "false",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://api.angolazone.com/api/categoreycreate", requestOptions)
    .then((response) => response.json())
    .then(() => {
      return "success";
    });
};

export const categoryActions = categorySlice.actions;

export default categorySlice;
