import Cookies from "js-cookie";
import axios from "axios";

const generateToken = async () => {
  const data2 = await axios.post(
    `https://api.angolazone.com/api/uid-token?uid=${Cookies.get("uid")}`
  );
  Cookies.set("token", data2.data.token);
  window.location.reload(false);
};

export const refreshToken = async () => {
  generateToken();
};
