import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const intitialPlansState = {
  allPlans: [],
  status: "idle",
  selectedPlanId: "",
  selectedPlanObject: {},
  fetchPlansResponse: "",
  getPlanByIdResponse: "idle",
};

export const fetchAllPlans = createAsyncThunk(
  "plans/fetchAllPlans",
  async () => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      "https://api.angolazone.com/api/Order/getPlan",
      requestOptions
    ).then((res) => res.json());
  }
);

export const getPlanById = createAsyncThunk("plans/getPlanById", async () => {
  var myHeaders = new Headers();
  myHeaders.append("firebasetoken", Cookies.get("token"));
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    "https://api.angolazone.com/api/Order/getPlan",
    requestOptions
  ).then((res) => res.json());
});

const plansSlice = createSlice({
  name: "planssSlice",
  initialState: intitialPlansState,
  reducers: {
    setSelectedPlanId(state, action) {
      state.selectedPlanId = action.payload;
      state.selectedPlanObject = state.allPlans.filter(
        (item) => item.id === action.payload
      );
    },
    setSelectedPlanObject(state, action) {
      state.selectedPlanObject = action.payload;
    },
  },
  extraReducers: {
    [fetchAllPlans.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAllPlans.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.allPlans = action.payload.plans;
      state.fetchPlansResponse = action.payload;
    },
    [fetchAllPlans.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getPlanById.pending]: (state, action) => {
      state.getPlanByIdResponse = "loading";
    },
    [getPlanById.fulfilled]: (state, action) => {
      state.getPlanByIdResponse = "succeeded";
      state.selectedPlanObject = action.payload.plans;
    },
    [getPlanById.rejected]: (state, action) => {
      state.getPlanByIdResponse = "failed";
    },
  },
});

export const planActions = plansSlice.actions;

export default plansSlice;
