import React, { useEffect, useState } from "react";
import AdsTable from "../../components/Ads Table/AdsTable";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";

const NewAds = () => {
  const data = useSelector((state) => state.ads.newAds);
  const status = useSelector((state) => state.ads.status);

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
          </div>
        </div>
      ) : (
        <AdsTable tableData={data} type={"New ads"} />
      )}
    </>
  );
};

export default NewAds;
