import React, { useState, useEffect } from "react";
import styles from "../styles/sidebar.module.css";
import AngolaLogo from "../assets/icons/angolaLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Categories from "../assets/icons/categIcon.png";
import userIcon from "../assets/icons/UsersIcon.png";
import dashboardIcon from "../assets/icons/dashboardIcon.png";
import AdListingIconNew from "../assets/icons/adList.png";
import { logout } from "../firebase";
import { auth } from "../firebase";
import Banners from "../assets/icons/adListingNew.png";
import logouticon from "../assets/icons/logOutIcon.png";
import admin1 from "../assets/images/avatar.png";
import { useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { authActions } from "../store/auth";
import Cookies from "js-cookie";
import plansIcon from "../assets/images/plans.png";
import subsIcon from "../assets/images/subscriptions.png";

const Sidebar = (props) => {
  const [openUserSubTab, setOpenUserSubTab] = useState(false);
  const [openAddSubTab, setOpenAddSubTab] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const location = useLocation();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, loading]);

  const navigate = useNavigate();

  const onLogOut = () => {
    logout();
  };

  return (
    <div className="container">
      <div className="navBar">
        <div className="logo-container">
          <img className={styles.angolaLogo} src={AngolaLogo} alt="logo" />
        </div>
        <div className="profileOptions">
          <h2>Welcome Admin</h2>
          <img className="adminImage" src={admin1} alt="img" />
        </div>
      </div>
      <div className="dashboardView">
        <div className={`${styles.sidebar}`}>
          <div className={`${styles.menu}`}>
            <NavLink
              onClick={() => {
                setOpenAddSubTab(false);
                setOpenUserSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/dashboard"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon} src={dashboardIcon} />
              Dashboard
            </NavLink>
            <NavLink
              onClick={() => {
                setOpenAddSubTab(false);
                setOpenUserSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/categories"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon} src={Categories} />
              Categories
            </NavLink>

            <NavLink
              onClick={() => {
                setOpenUserSubTab(!openUserSubTab);
                setOpenAddSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/users"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon} src={userIcon} />
              Users
            </NavLink>

            {openUserSubTab && (
              <div className={styles.userSubCategory}>
                {/* <NavLink
                  style={{ textDecoration: "none" }}
                  to={"/users/activeUsers"}
                  className={styles.linkSmall}
                  activeclassname="active2"
                >
                  Active Users
                </NavLink> */}

                <button
                  onClick={() => {
                    navigate("/users/activeUsers");
                  }}
                  className={
                    location.pathname === "/users/activeUsers" ||
                    location.pathname === "/users" ||
                    location.pathname.includes("/users/activeUsers/user")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  {" "}
                  Active Users
                </button>

                {/* <NavLink
                  style={{ textDecoration: "none" }}
                  to={"/users/blockedUsers"}
                  className={styles.linkSmall}
                  activeclassname="activeUser1"
                >
                  Blocked Users
                </NavLink> */}

                <button
                  onClick={() => {
                    navigate("/users/blockedUsers");
                  }}
                  className={
                    location.pathname === "/users/blockedUsers" ||
                    location.pathname.includes("/users/blockedUsers/user")
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  {" "}
                  Blocked Users
                </button>
              </div>
            )}
            <NavLink
              onClick={() => {
                setOpenAddSubTab(!openAddSubTab);
                setOpenUserSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/ads"}
              className={styles.link}
              activeclassname="active"
            >
              <img alt="logo" className={styles.icon2} src={AdListingIconNew} />
              Ad Listing
            </NavLink>

            {openAddSubTab && (
              <div className={styles.addSubCategory}>
                {/* <NavLink
                  style={{ textDecoration: "none" }}
                  to={"/ads/newAds"}
                  className={styles.linkSmall}
                  activeclassname="active"
                >
                  New Ads
                </NavLink> */}

                <button
                  onClick={() => {
                    navigate("/ads/newAds");
                  }}
                  className={
                    location.pathname === "/ads/newAds"
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  {" "}
                  New Ads
                </button>

                <button
                  onClick={() => {
                    navigate("/ads/activeAds");
                  }}
                  className={
                    location.pathname === "/ads/activeAds" ||
                    location.pathname === "/ads"
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  {" "}
                  Active Ads
                </button>

                {/* <NavLink
                  style={{ textDecoration: "none" }}
                  to={"/ads/activeAds"}
                  className={styles.linkSmall}
                  activeclassname="activeUser1"
                >
                  Active Ads
                </NavLink> */}

                <button
                  onClick={() => {
                    navigate("/ads/deactivatedAds");
                  }}
                  className={
                    location.pathname === "/ads/deactivatedAds"
                      ? "selectedTab"
                      : "normalTab"
                  }
                >
                  {" "}
                  Deactivated Ads
                </button>

                {/* <NavLink
                  style={{ textDecoration: "none" }}
                  to={"/ads/deactivatedAds"}
                  className={styles.linkSmall}
                  activeclassname="activeUser1"
                >
                  Deactivated Ads
                </NavLink> */}
              </div>
            )}
            <NavLink
              onClick={() => {
                setOpenAddSubTab(false);
                setOpenUserSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/banners"}
              className={styles.link}
              activeclassname={styles.active}
            >
              <img alt="logo" className={styles.icon} src={Banners} />
              Banners
            </NavLink>
            <NavLink
              onClick={() => {
                setOpenAddSubTab(false);
                setOpenUserSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/premiumplans"}
              className={styles.link}
              activeclassname={styles.active}
            >
              <img alt="logo" className={styles.icon3} src={plansIcon} />
              Subscription Plans
            </NavLink>
            <NavLink
              onClick={() => {
                setOpenAddSubTab(false);
                setOpenUserSubTab(false);
              }}
              style={{ textDecoration: "none" }}
              to={"/transactions"}
              className={styles.link}
              activeclassname={styles.active}
            >
              <img alt="logo" className={styles.icon3} src={subsIcon} />
              Transactions
            </NavLink>
          </div>

          <div className={`${styles.menu} `}>
            <button onClick={() => onLogOut()} className={styles.logout}>
              <img alt="logo" className={styles.icon2} src={logouticon} />
              <p className="red">Logout</p>
            </button>
          </div>
        </div>
        <main className="right-side">{props.children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
