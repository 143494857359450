import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialBannersState = {
  allBanners: [],
  selectedBannerID: "",
  selectedBannerObject: "",
  status: "idle",
  getBannerById: [],
  getBannerByIdResponse: "idle",
};

export const fetchAllBanners = createAsyncThunk(
  "banners/fetchBanners",
  async (body) => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      "https://api.angolazone.com/api/all-banner",
      requestOptions
    ).then((res) => res.json());
  }
);

export const getBannerById = createAsyncThunk(
  "banners/getBannerById",
  async (id) => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.angolazone.com/api/all-banner?_id=${id}`,
      requestOptions
    ).then((res) => res.json());
  }
);

const bannerSlice = createSlice({
  name: "bannerSlice",
  initialState: initialBannersState,
  reducers: {
    setSelectedBannerId(state, action) {
      state.selectedBannerID = action.payload;
      state.selectedBannerObject = state.allBanners.filter(
        (item) => item._id === action.payload
      );
    },
  },
  extraReducers: {
    [fetchAllBanners.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAllBanners.fulfilled]: (state, { payload }) => {
      state.status = "succeeded";
      state.allBanners = payload.banners;
    },
    [fetchAllBanners.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getBannerById.pending]: (state, action) => {
      state.getBannerByIdResponse = "loading";
    },
    [getBannerById.fulfilled]: (state, { payload }) => {
      state.getBannerByIdResponse = "succeeded";
      state.selectedBannerObject = payload.banners;
    },
    [getBannerById.rejected]: (state, action) => {
      state.getBannerByIdResponse = "failed";
    },
  },
});

export const bannerActions = bannerSlice.actions;

export default bannerSlice;
