import React, { useState, useEffect } from "react";
import styles from "../../styles/user.module.css";

import alert from "../../assets/icons/alert.png";
import cross from "../../assets/icons/cancel2.png";
import chevLeft from "../../assets/icons/chevleft.png";
import { useSelector, useDispatch } from "react-redux";
import ConfirmDeleteUser from "./ConfirmDeleteUser";
import ConfirmActivateUser from "./ConfirmActivateUser";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { useNavigate, useParams } from "react-router-dom";
import userAlt from "../../assets/images/user.svg";
import { getUserAds, getUserById, getUserReviews } from "../../store/users";
import { BarLoader, ScaleLoader } from "react-spinners";
import UserAdsTable from "../../components/User Table/UserAds";
import moment from "moment";
import ReactStars from "react-rating-stars-component";

const User = () => {
  const dispatch = useDispatch();
  // const selectedUser = useSelector((state) => state.users.selectedUserId);
  const getUserByIdReponse = useSelector(
    (state) => state.users.getUserByIdReponse
  );
  const userAds = useSelector((state) => state.users.userAds);
  const userAdStatus = useSelector((state) => state.users.userAdsGetStatus);
  const userReviews = useSelector((state) => state.users.userReviews);
  const userReviewStatus = useSelector(
    (state) => state.users.userReviewsGetStatus
  );

  const selectedUserObject = useSelector(
    (state) => state.users.selecedUserObject
  );

  useEffect(() => {
    if (!selectedUserObject.length) {
      dispatch(getUserById(id));
    }
  }, []);

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const idRaw = useParams();

  const id = String(idRaw.userId).slice(1);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedUserObject) {
      dispatch(getUserAds({ id: id }));
      dispatch(getUserReviews({ id: id }));
    }
  }, [selectedUserObject]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [tab, setTab] = useState("ads");

  const renderTable = () => {
    if (userAdStatus === "loading") {
      return (
        <div className={styles.center}>
          <ScaleLoader color="red" />
        </div>
      );
    }

    if (userAdStatus === "succeeded") {
      return <UserAdsTable tableData={userAds.data} />;
    }
  };

  const renderReviews = () => {
    if (userReviewStatus === "loading") {
      return (
        <div className={styles.center}>
          <ScaleLoader color="red" />
        </div>
      );
    }

    if (userReviewStatus === "succeeded" && userReviews.data.length === 0) {
      return <h1>No reviews posted for this user.</h1>;
    }

    if (userReviewStatus === "succeeded") {
      return userReviews?.data?.map((item) => (
        <div className={styles.reviews}>
          <div className={styles.reviewRow}>
            <h1 className="red no-margin">
              {item.reviewedBy.firstName + " " + item.reviewedBy.lastName}
            </h1>
            <ReactStars count={5} value={item.rating} size={20} edit={false} />

            <p className={styles.createdAt}>
              {moment(item.createdAt).format("Do MMM YYYY")}
            </p>
          </div>

          <h3>{item.review}</h3>
        </div>
      ));
    }
  };

  const notifySuccess = () =>
    toast.success("User deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifySuccess2 = () =>
    toast.success("User activated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Session Expired, Please log in again.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const onImageError = (ev) => {
    ev.target.src = userAlt;
  };

  return (
    <div className={styles.mainContainerUser}>
      <ToastContainer />

      <ConfirmDeleteUser
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        id={selectedUserObject[0]?.id}
        notifySuccess={notifySuccess}
        notifyError={notifyError}
      />

      <ConfirmActivateUser
        openActivateModal={openActivateModal}
        setOpenActivateModal={setOpenActivateModal}
        id={selectedUserObject[0]?.id}
        notifySuccess={notifySuccess2}
        notifyError={notifyError}
      />
      <div className={styles.userContainer}>
        <div className={styles.headingAndNavigation}>
          <button
            className="btn-back"
            onClick={() => navigate("/users/blockedUsers")}
          >
            <img src={chevLeft} alt="back" />
          </button>

          <h1>Blocked User</h1>
        </div>

        {getUserByIdReponse === "loading" ? (
          <BarLoader color="red" />
        ) : (
          <div className={styles.userDetails}>
            <div className={styles.nameAndProfilePic}>
              <img
                onError={onImageError}
                src={selectedUserObject[0]?.uploadImage}
                alt="profile pic"
              />
              <h1 className="red">
                {capitalizeName(
                  selectedUserObject[0]?.firstName +
                    " " +
                    selectedUserObject[0]?.lastName
                )}
              </h1>
            </div>

            <div className={styles.ratingAndReviews}>
              <h3>Seller Rating</h3>
              <h1 className="red">{selectedUserObject[0]?.ratingsAverage}</h1>
              <ReactStars
                count={5}
                value={selectedUserObject[0]?.ratingsAverage}
                // onChange={ratingChanged}
                size={30}
                edit={false}
              />
              <h4 onClick={() => setTab("reviews")} className="red userName">
                See all reviews
              </h4>{" "}
            </div>

            <div className={styles.userdetailsAndActions}>
              <div className={styles.info}>
                <div className={styles.data}>
                  <label>ID</label>
                  <h2>#{selectedUserObject[0]?.id}</h2>
                  <label>Phone no.</label>
                  <h2>{selectedUserObject[0]?.phoneNumber}</h2>
                </div>

                <div className={styles.data}>
                  <label>Email</label>
                  <h2>{selectedUserObject[0]?.email}</h2>
                  <label>Location</label>
                  <h2>
                    {selectedUserObject[0]?.userLocation.address +
                      ", " +
                      selectedUserObject[0]?.userLocation.city}
                  </h2>
                </div>
              </div>

              <h2>
                Suspended on{" "}
                <span className="red">
                  {moment(selectedUserObject[0]?.suspensionDates).format(
                    "D MMM YYYY"
                  )}
                </span>
              </h2>

              <div className={styles.data2}>
                <h2>Reason of suspension</h2>
                <label>{selectedUserObject[0]?.comment}</label>
              </div>

              <div className={styles.actionButtons}>
                <button
                  className="btn-save"
                  onClick={() => setOpenActivateModal(true)}
                >
                  <img src={alert} alt="save" />
                  Unblock user
                </button>
                <button
                  className="btn-cancel"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  <img src={cross} alt="cancel" />
                  Remove
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.tabContainer}>
        <div className={styles.tabNames}>
          <h2
            className={`${tab === "ads" ? "underline" : ""} userName`}
            onClick={() => setTab("ads")}
          >
            Ads
          </h2>
          <h2
            className={`${tab === "reviews" ? "underline" : ""} userName`}
            onClick={() => setTab("reviews")}
          >
            {" "}
            Reviews
          </h2>
        </div>
        <div className={styles.tabBody}>
          {tab === "ads" ? renderTable() : renderReviews()}
        </div>
      </div>
    </div>
  );
};

export default User;
