import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import TransactionsTable from "../../components/Transactions/TransactionsTable";
import { fetchAllTransactions } from "../../store/transactions";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";
import Cookies from "js-cookie";

const Transactions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      response?.error?.code === "auth/id-token-expired" ||
      response?.error?.code === "auth/argument-error"
    ) {
      dispatch(authActions.setTokenError(true));
      Cookies.set("tokenError", true);
      sessionExpireLogin();
    }
  }, []);

  const status = useSelector((state) => state.transactions.status);

  useEffect(() => {
    dispatch(fetchAllTransactions());
  }, [dispatch]);

  const data = useSelector((state) => state.transactions.allTransactions);
  const response = useSelector(
    (state) => state.transactions.getTransactionsReponse
  );

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
            <p className="fetchText"> Fetching transactions.. </p>
          </div>
        </div>
      ) : (
        <>
          {response?.error?.code === "auth/id-token-expired" ||
          response?.error?.code === "auth/argument-error" ? (
            <h1 className="noData">No transactions at the moment</h1>
          ) : (
            <TransactionsTable tableData={data} type={"Transactions"} />
          )}
        </>
      )}
    </>
  );
};

export default Transactions;
