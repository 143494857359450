import React from "react";
import styles from "../../styles/user.module.css";
import { NavLink } from "react-router-dom";
import chevLeft from "../../assets/icons/chevleft.png";
import profilePic from "../../assets/icons/profilePic1.png";
import reviewStarGold from "../../assets/icons/reviewStarGold.png";
import reviewStarFaded from "../../assets/icons/reviewStarFaded.png";
import alert from "../../assets/icons/alert.png";
import cross from "../../assets/icons/cancelCross.png";

const User = () => {
  return (
    <div className={styles.mainContainerUser}>
      <div className={styles.userContainer}>
        <div className={styles.headingAndNavigation}>
          <NavLink to="/users">
            <img src={chevLeft} alt="back" />
          </NavLink>

          <h1>Active User</h1>
        </div>
        <div className={styles.userDetails}>
          <div className={styles.nameAndProfilePic}>
            <img src={profilePic} alt="profile pic" />
            <h1 className="red">John Jermy</h1>
          </div>

          <div className={styles.ratingAndReviews}>
            <h3>Seller Rating</h3>
            <h1 className="red">4.0</h1>
            <div className={styles.reviewStars}>
              <img src={reviewStarGold} alt="*" />
              <img src={reviewStarGold} alt="*" />
              <img src={reviewStarGold} alt="*" />
              <img src={reviewStarGold} alt="*" />
              <img src={reviewStarFaded} alt="*" />
            </div>
            <h4 className="red">See all reviews</h4>
          </div>

          <div className={styles.userdetailsAndActions}>
            <div className={styles.info}>
              <div className={styles.data}>
                <label>ID</label>
                <h2>#1245799</h2>
                <label>Phone no</label>
                <h2>+91 95545 94342</h2>
              </div>

              <div className={styles.data}>
                <label>Email</label>
                <h2>bowen.shawn@gmail.com</h2>
                <label>Location</label>
                <h2>Andheri, Mumbai, MH</h2>
              </div>
            </div>

            <div className={styles.actionButtons}>
              <button className="btn-save">
                <img src={alert} alt="save" />
                Block User
              </button>
              <button className="btn-cancel">
                <img src={cross} alt="cancel" />
                Remove
              </button>
            </div>
          </div>
        </div>
        aa
      </div>

      <div className={styles.postedAdsTable}></div>
    </div>
  );
};

export default User;
