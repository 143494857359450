import { configureStore } from "@reduxjs/toolkit";

import adsSlice from "./ads";
import authSlice from "./auth";
import categorySlice from "./categories";
import userSlice from "./users";
import bannerSlice from "./banners";
import plansSlice from "./plans";
import transactionsSlice from "./transactions";
import dashboardSlice from "./dashboard";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ads: adsSlice.reducer,
    categories: categorySlice.reducer,
    users: userSlice.reducer,
    banners: bannerSlice.reducer,
    plans: plansSlice.reducer,
    transactions: transactionsSlice.reducer,
    dashboard: dashboardSlice.reducer,
  },
});

export default store;
