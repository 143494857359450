import React from "react";
import AddCategory from "./pages/Categories/AddCategory";
import NewAds from "./pages/AdListing/NewAds";
import ActiveAds from "./pages/AdListing/ActiveAds";
import DeactivatedAds from "./pages/AdListing/DeactivatedAds";
import AdIndividualNew from "./pages/AdListing/AdIndividualNew";
import Login from "./pages/Login";
import Ads from "./pages/AdListing/Ads";
import User from "./pages/Users/User";
import ProtectedRoute from "./protected.route";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Categories from "./pages/Categories/Categories";
import ActiveUsers from "./pages/Users/ActiveUsers";
import BlockedUsers from "./pages/Users/BlockedUsers";
import SideBar from "./components/Sidebar";
import EditCategory from "./pages/Categories/EditCategory";
import UserIndividualBlocked from "./pages/Users/UserIndividualBlocked";
import UserIndividualActive from "./pages/Users/UserIndividualActive";
import Banners from "./pages/Banners/Banners";
import AddBanner from "./pages/Banners/AddBanner";
import UpdateBanner from "./pages/Banners/UpdateBanner";
import AdIndividualUser from "./pages/AdListing/AdIndividualUser";
import Transactions from "./pages/Transactions/Transactions";
import PremiumPlans from "./pages/Premium Plans/PremiumPlans";
import AddPlan from "./pages/Premium Plans/CreatePlan";
import UpdatePlan from "./pages/Premium Plans/UpdatePlan";
import Transaction from "./pages/Transactions/Transaction";
import ViewPlan from "./pages/Premium Plans/ViewPlan";

const RouteEnum = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <SideBar>
                <Dashboard />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <ProtectedRoute>
              <SideBar>
                <Categories />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/categories/addCategory"
          element={
            <ProtectedRoute>
              <SideBar>
                <AddCategory />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories/updateCategory/:catergoryId"
          element={
            <ProtectedRoute>
              <SideBar>
                <EditCategory />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <SideBar>
                <User />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <SideBar>
                <ActiveUsers />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="users/activeUsers"
          element={
            <ProtectedRoute>
              <SideBar>
                <ActiveUsers />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="users/activeUsers/user/:userId"
          element={
            <ProtectedRoute>
              <SideBar>
                <UserIndividualActive />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/blockedUsers"
          element={
            <ProtectedRoute>
              <SideBar>
                <BlockedUsers />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="users/blockedUsers/user/:userId"
          element={
            <ProtectedRoute>
              <SideBar>
                <UserIndividualBlocked />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ads"
          element={
            <ProtectedRoute>
              <SideBar>
                <Ads />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ads/newAds"
          element={
            <ProtectedRoute>
              <SideBar>
                <NewAds />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ads/activeAds"
          element={
            <ProtectedRoute>
              <SideBar>
                <ActiveAds />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ads/deactivatedAds"
          element={
            <ProtectedRoute>
              <SideBar>
                <DeactivatedAds />
              </SideBar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ads/deactivatedAds/ad/:adId"
          element={
            <ProtectedRoute>
              <SideBar>
                <AdIndividualNew />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/ads/ad"
          element={
            <ProtectedRoute>
              <SideBar>
                <AdIndividualUser />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/ads/activeAds/ad/:adId"
          element={
            <ProtectedRoute>
              <SideBar>
                <AdIndividualNew />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/ads/newAds/ad/:adId"
          element={
            <ProtectedRoute>
              <SideBar>
                <AdIndividualNew />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/banners"
          element={
            <ProtectedRoute>
              <SideBar>
                <Banners />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/banners/addBanner"
          element={
            <ProtectedRoute>
              <SideBar>
                <AddBanner />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/banners/updateBanner/:bannerId"
          element={
            <ProtectedRoute>
              <SideBar>
                <UpdateBanner />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/premiumplans"
          element={
            <ProtectedRoute>
              <SideBar>
                <PremiumPlans />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/premiumplans/addPlan"
          element={
            <ProtectedRoute>
              <SideBar>
                <AddPlan />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/premiumplans/updatePlan"
          element={
            <ProtectedRoute>
              <SideBar>
                <UpdatePlan />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/premiumplans/viewPlan/:planId"
          element={
            <ProtectedRoute>
              <SideBar>
                <ViewPlan />
              </SideBar>
            </ProtectedRoute>
          }
        />

        {/* <Route
          path="/premiumplans/planDetails"
          element={
            <ProtectedRoute>
              <SideBar>
                <PlanIndividual />
              </SideBar>
            </ProtectedRoute>
          }
        /> */}

        <Route
          path="/transactions"
          element={
            <ProtectedRoute>
              <SideBar>
                <Transactions />
              </SideBar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/transactions/transaction/:transactionId"
          element={
            <ProtectedRoute>
              <SideBar>
                <Transaction />
              </SideBar>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteEnum;
