import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, auth1 } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import angolaLogo from "../assets/icons/angolaLogo.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { getSessionToken, authActions } from "../store/auth";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { ScaleLoader } from "react-spinners";
import CryptoJS from "crypto-js";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMesaage] = useState("");

  const [showError, setShowError] = useState(false);

  const login2 = async (values) => {
    setLoginLoading(true);

    try {
      setShowError(false);
      const response = await signInWithEmailAndPassword(
        auth1,
        values.email,
        values.password
      );

      var myHeaders = new Headers();
      myHeaders.append("firebasetoken", response.user.accessToken);
      var raw = "";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const loginResponse = await fetch(
        "https://api.angolazone.com/user/login",
        requestOptions
      );

      const data = await loginResponse.json();

      if (data.status && data.user.userType === "admin") {
        dispatch(authActions.setAuthentication(true));
        dispatch(getSessionToken(data.user.firebaseUid));
        navigate("/dashboard");
      } else if (data.user.userType !== "admin") {
        setShowError(true);
        setLoginLoading(false);
      }
    } catch (error) {
      setShowError(true);
      setLoginLoading(false);
    }
  };

  // useEffect(() => {
  //   if (loading) {
  //     // maybe trigger a loading screen
  //     return;
  //   }

  //   if (!user) {
  //     Cookies.set("isAuthenticated", false);
  //   }

  //   if (user) {
  //     dispatch(authActions.setFirebaseToken(user.accessToken));
  //     Cookies.set("isAuthenticated", false);

  //     var myHeaders = new Headers();
  //     myHeaders.append("firebasetoken", user.accessToken);
  //     var raw = "";
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     fetch("https://api.angolazone.com/user/login", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.user.userType === "admin") {
  //           dispatch(authActions.setAuthentication(true));
  //           Cookies.set("isAuthenticated", true);
  //           dispatch(getSessionToken(result.user.firebaseUid));
  //           navigate("/dashboard");
  //           setLoginLoading(false);
  //         } else if (result.user.userType === "user") {
  //           alert("Invalid access");
  //           setLoginLoading(false);
  //         }
  //       })
  //       .catch((error) => setLoginLoading(false));
  //   }
  // }, [user, loading]);

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    password: Yup.string().required("Password is a required field"),
  });

  return (
    <div className="loginContainer">
      <div className="loginBody">
        <img alt="Logo" src={angolaLogo} />
        <div className="formikCont">
          <Formik
            validationSchema={schema}
            initialValues={{ email: "", password: "" }}
            onSubmit={
              (values) => login2(values)
              // authActions.login({
              //   email: values.email,
              //   password: values.password,
              //   setLoginLoading,
              //   setLoginError,
              //   setErrorMesaage,
              // })
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div className="login">
                <div className="form">
                  <form noValidate onSubmit={handleSubmit}>
                    <span>Login</span>

                    {/* <button type="button" onClick={() => encryptData()}>
                      Encrypt
                    </button>
                    <button type="button" onClick={() => decryptData()}>
                      Decrypt
                    </button>
                    <h1>{text}</h1>
                    <p>{encrptedData}</p>
                    <h1>{decrptedData}</h1> */}

                    <div className="errorField">
                      {" "}
                      {loginError && (
                        <p className="red">{errorMessage.slice(10)}</p>
                      )}
                    </div>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Enter email id / username"
                      className="form-control inp_text"
                      id="email"
                      autoComplete="email"
                      onClick={() => setLoginError(false)}
                    />
                    <p className="error">
                      {errors.email && touched.email && errors.email}
                    </p>
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder="Enter password"
                      className="form-control"
                      autoComplete="password"
                    />
                    <p className="error">
                      {errors.password && touched.password && errors.password}
                    </p>

                    {showError && <p className="red">Invalid Access</p>}

                    <button type="submit">
                      {loginLoading ? (
                        <ScaleLoader
                          height="20px"
                          padding="0px"
                          color="white"
                          className="margin-rem"
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
