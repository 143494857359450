import { initializeApp } from "firebase/app";
import { toast } from "react-toastify";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import Cookies from "js-cookie";
import { refreshToken } from "./functions";

const firebaseConfig = {
  apiKey: "AIzaSyAoULpaewZAYZ4v47VQv4RDseg8eMA2wxg",
  authDomain: "angolazone-6f8cc.firebaseapp.com",
  projectId: "angolazone-6f8cc",
  storageBucket: "angolazone-6f8cc.appspot.com",
  messagingSenderId: "48943126918",
  appId: "1:48943126918:web:de19cd78fcd7868e1cb407",
  measurementId: "G-LF4F85LFK9",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export const auth1 = getAuth(app);

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    toast.error("Invalid User", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};
// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     const result = await signInWithEmailAndPassword(auth, email, password);
//   } catch (err) {
//     setErrorMesaage(err.message);

//     // console.error(err);
//     setLoginLoading(false);
//     // alert(err.message);
//     setLoginError(true);
//   }
// };

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
    // setLoginError(true)
  }
};
const logout = () => {
  Cookies.set("isAuthenticated", false);
  signOut(auth);
};

const sessionExpireLogin = () => {
  // Cookies.set("isAuthenticated", false);
  refreshToken();
};

export {
  auth,
  db,
  signInWithGoogle,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  sessionExpireLogin,
};
