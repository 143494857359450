import React, { useState } from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/trashModal.svg";
import styles from "../../styles/adDetail.module.css";
import cross from "../../assets/icons/cancelCross.png";
import cross2 from "../../assets/icons/cancel2.png";
import alert from "../../assets/icons/alert.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { getUserAds } from "../../store/users";
import { useRef } from "react";
import Cookies from "js-cookie";

const ConfirmDeleteAdModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  id,
  notifySuccess,
  userId,
}) => {
  // const sessionToken = useSelector((state) => state.auth.sessionToken);

  const sessionToken = Cookies.get("token");

  const selectedAdId = useSelector((state) => state.ads.selectedAdId);
  let btnRef = useRef();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onDeactivateClick = (body) => {
    setLoading(true);
    btnRef.current.setAttribute("disabled", "disabled");

    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", sessionToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/admin/ad/${body.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setLoading(false);
          notifySuccess();
          setOpenDeleteModal(false);
          dispatch(getUserAds({ id: userId }));
        }
      });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };
  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openDeleteModal}
      shouldCloseOnEsc
      onRequestClose={() => setOpenDeleteModal(false)}
      style={bg}
    >
      <div className={styles.removeAd}>
        <img
          className={styles.cancelCross}
          src={cross}
          alt="X"
          onClick={() => setOpenDeleteModal(false)}
        />
        <img className="modalDelete" alt="Delete" src={confirmRemove} />
        <h1>Are you sure you want to remove this ad</h1>

        <div className={styles.actionButtons}>
          <button
            className="btn-save"
            onClick={() =>
              onDeactivateClick({
                token: sessionToken,
                id: id,
              })
            }
          >
            <img src={alert} alt="save" />
            {loading ? <ScaleLoader color="white" /> : "Remove"}
          </button>
          <button
            className="btn-cancel"
            onClick={() => setOpenDeleteModal(false)}
            ref={btnRef}
          >
            <img src={cross2} alt="cancel" />
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteAdModal;
