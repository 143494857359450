import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const intialUsersState = {
  allUsers: [],
  userFetchStatus: "idle",
  activeUsers: [],
  blockedUsers: [],
  selectedUserId: "",
  selecedUserObject: {},
  userAds: [],
  userAdsGetStatus: "idle",
  userReviewsGetStatus: "idle",
  userReviews: [],
  userFetchStatus: "",
  getUserReponse: "",
  getUserById: [],
  getUserByIdReponse: "idle",
};

export const fetchAllUsers = createAsyncThunk("users/fetchUsers", async () => {
  var myHeaders = new Headers();
  myHeaders.append("firebasetoken", Cookies.get("token"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    "https://api.angolazone.com/api/admin/getAllUsers",
    requestOptions
  ).then((response) => response.json());
});

export const getUserById = createAsyncThunk("users/getUserById", async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("firebasetoken", Cookies.get("token"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `https://api.angolazone.com/api/admin/getAllUsers?_id=${id}`,
    requestOptions
  ).then((response) => response.json());
});

export const getUserAds = createAsyncThunk(
  "users/getUserAds",
  async ({ id }) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.angolazone.com/api/ads/getAdsByUser/${id}`,
      requestOptions
    ).then((response) => response.json());
  }
);

export const getUserReviews = createAsyncThunk(
  "users/getUserReviews",
  async ({ id }) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `https://api.angolazone.com/api/review/${id}`,
      requestOptions
    ).then((response) => response.json());
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState: intialUsersState,
  reducers: {
    setAllUsers(state, action) {
      state.allUsers = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUserId = action.payload;
      state.selecedUserObject = state.allUsers.filter(
        (item) => item.id === action.payload
      );
    },
  },
  extraReducers: {
    [fetchAllUsers.pending]: (state, action) => {
      state.userFetchStatus = "loading";
    },
    [fetchAllUsers.fulfilled]: (state, { payload }) => {
      state.userFetchStatus = "succeeded";
      state.getUserReponse = payload;
      state.allUsers = payload.users;
      state.userFetchStatus = payload;
      state.activeUsers = payload.users.filter(
        (item) => item.active === true && item.userType === "user"
      );
      state.blockedUsers = payload.users.filter(
        (item) => item.active === false && item.userType === "user"
      );
    },
    [fetchAllUsers.rejected]: (state, action) => {
      state.userFetchStatus = "failed";
    },
    [getUserAds.pending]: (state, action) => {
      state.userAdsGetStatus = "loading";
    },
    [getUserAds.fulfilled]: (state, { payload }) => {
      state.userAdsGetStatus = "succeeded";
      state.userAds = payload;
    },
    [getUserAds.rejected]: (state, action) => {
      state.userAdsGetStatus = "failed";
    },
    [getUserReviews.pending]: (state, action) => {
      state.userReviewsGetStatus = "loading";
    },
    [getUserReviews.fulfilled]: (state, { payload }) => {
      state.userReviewsGetStatus = "succeeded";
      state.userReviews = payload;
    },
    [getUserReviews.rejected]: (state, action) => {
      state.userReviewsGetStatus = "failed";
    },

    [getUserAds.rejected]: (state, action) => {
      state.userAdsGetStatus = "failed";
    },
    [getUserById.pending]: (state, action) => {
      state.getUserByIdReponse = "loading";
    },
    [getUserById.fulfilled]: (state, { payload }) => {
      state.getUserByIdReponse = "succeeded";
      state.selecedUserObject = payload.users;
    },
    [getUserById.rejected]: (state, action) => {
      state.getUserByIdReponse = "failed";
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
