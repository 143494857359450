import React, { useEffect } from "react";
import styles from "../../styles/addCategory.module.css";
import chevLeft from "../../assets/icons/chevleft.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  getTransactionById,
  getTransactionById2,
} from "../../store/transactions";
import { BarLoader } from "react-spinners";

const Transaction = () => {
  const idRaw = useParams();
  const id = String(idRaw.transactionId).slice(1);
  const dispatch = useDispatch();

  const getTransactionByIdResponse = useSelector(
    (state) => state.transactions.getTransactionByIdResponse
  );

  const selectedTransactionObject = useSelector(
    (state) => state.transactions.selectedTransactionObject
  );

  useEffect(() => {
    if (!selectedTransactionObject.length) {
      dispatch(getTransactionById2(id));
    }
  }, []);

  useEffect(() => {
    dispatch(getTransactionById(id));
  }, [dispatch]);

  const getTransactionbyIdObject = useSelector(
    (state) => state.transactions.transactionById
  );

  const status = useSelector((state) => state.transactions.transactionStatus);

  const navigate = useNavigate();

  const checkDate = (date) => {
    if (date?.slice(0, 1) === 1) {
      return `${date}`;
    }
    return `${date}`;
  };

  return (
    <div className="containerAdCategory">
      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate(-1)}>
          <img src={chevLeft} alt="back" />
        </button>

        <h1 className="s20"> Transaction</h1>
      </div>

      {getTransactionByIdResponse === "loading" ? (
        <BarLoader color="red" />
      ) : (
        <div className={styles.transactionDetials}>
          <div className={styles.column}>
            <h2>Transaction Details</h2>
            <div className={styles.details}>
              <label>Plan Name</label>
              <p>{selectedTransactionObject[0]?.planName}</p>
            </div>

            <div className={styles.details}>
              <label>Price</label>
              <p>$ {selectedTransactionObject[0]?.price}</p>
            </div>

            <div className={styles.details}>
              <label>Duration</label>
              {checkDate(selectedTransactionObject[0]?.durations)}
            </div>

            <div className={styles.details}>
              <label>Stripe Id </label>
              <p>{selectedTransactionObject[0]?.stripeId}</p>
            </div>
            <div className={styles.details}>
              <label>Subscription Id </label>
              <p>{selectedTransactionObject[0]?.subscriptionId}</p>
            </div>

            <div className={styles.details}>
              <label>Start Date</label>
              <p>
                {moment(selectedTransactionObject[0]?.startDate).format(
                  "MM-DD-YYYY"
                )}
              </p>
            </div>

            <div className={styles.details}>
              <label>End Date</label>
              <p>
                {moment(selectedTransactionObject[0]?.endDate).format(
                  "MM-DD-YYYY"
                )}
              </p>
            </div>
          </div>

          <div className={styles.column}>
            <h2>Ad Details</h2>

            <div className={styles.details}>
              <label>Id </label>
              <div className={styles.premiumAndId}>
                <p>{selectedTransactionObject[0]?.adId}</p>
              </div>
            </div>
            {status === "succeeded" ? (
              <>
                <div className={styles.details}>
                  <label>Title </label>
                  <p>{getTransactionbyIdObject?.ad?.title}</p>
                </div>
                <div className={styles.details}>
                  <label>Price </label>
                  <p> $ {getTransactionbyIdObject?.ad?.price}</p>
                </div>

                <div className={styles.details}>
                  <label>Premium </label>
                  <p>
                    {" "}
                    {getTransactionbyIdObject?.ad?.isPremium ? "Yes" : "No"}
                  </p>
                </div>

                <div className={styles.details}>
                  <label>Posted at </label>
                  <p>
                    {moment(getTransactionbyIdObject?.ad?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </p>
                </div>

                <div className={styles.details}>
                  <label>Sold </label>
                  <p>{getTransactionbyIdObject?.ad?.isAdSold ? "Yes" : "No"}</p>
                </div>

                <div className={styles.details}>
                  <label>Location </label>
                  <p>{getTransactionbyIdObject?.ad?.adLocation?.address}</p>
                </div>

                {/* <div className={styles.details}>
                <label>Title </label>
                <p>{getTransactionbyIdObject?.ad?.title}</p>
              </div> */}
              </>
            ) : (
              <BarLoader color="red" />
            )}
          </div>

          <div className={`${styles.column} ${styles.alignLeft}`}>
            <h2>Seller Details</h2>

            {status === "succeeded" ? (
              <>
                <img
                  className="seller"
                  src={getTransactionbyIdObject?.user?.uploadImage}
                />

                <div className={styles.details}>
                  <label>Id </label>
                  <p> {getTransactionbyIdObject?.user?.id}</p>
                </div>

                <div className={styles.details}>
                  <label>Name </label>
                  <p>
                    {getTransactionbyIdObject?.user?.firstName +
                      " " +
                      getTransactionbyIdObject?.user?.lastName}
                  </p>
                </div>

                <div className={styles.details}>
                  <label>Active </label>
                  <p>
                    {" "}
                    {getTransactionbyIdObject?.user?.active ? "Yes" : "No"}
                  </p>
                </div>

                <div className={styles.details}>
                  <label>Rating </label>
                  <p> {getTransactionbyIdObject?.user?.ratingsAverage}</p>
                </div>
              </>
            ) : (
              <BarLoader color="red" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Transaction;
