import React, { useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./GlobalFilter_Ads";
import chevRightRed from "../../assets/icons/chevRight.png";
import chevLeftRed from "../../assets/icons/chevLeftRed.png";
import styles from "../Categories Table/cat.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import eye2 from "../../assets/icons/EyeNew.png";

import { transactionActions } from "../../store/transactions";

const TransactionsTable = ({ tableData, type }) => {
  const navigate = useNavigate();

  const data = useMemo(() => tableData, []);
  const dispatch = useDispatch();

  const onRowClick = (id) => {
    navigate(`/transactions/transaction/:${id}`);
    dispatch(transactionActions.setSelectedTransactionId(id));
  };

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const COLUMNS_ADSTABLE = [
    {
      Header: () => <p className={styles.headerID}>Transaction Id</p>,
      accessor: "_id",
      Cell: (tableProps) => (
        <p className={styles.id}>
          #{String(tableProps.row.original._id).slice(-4)}
        </p>
      ),
    },

    {
      Header: "Plan Name",
      accessor: "planName",
    },

    {
      Header: "Price",
      accessor: "price",
      Cell: (tableProps) => (
        <p className="red">$ {tableProps.row.original.price}</p>
      ),
    },
    {
      Header: "Seller name",
      accessor: "sellerName",
      Cell: (tableProps) => (
        <p>{capitalizeName(tableProps.row.original.sellerName)}</p>
      ),
    },
    {
      Header: "Duration",
      accessor: "durations",
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-delete2"
            onClick={() => onRowClick(tableProps.row.original._id)}
          >
            <img src={eye2} alt="View" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_ADSTABLE, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => {
    //     return [
    //       {
    //         id: "selection",
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <Checkbox {...getToggleAllRowsSelectedProps()} />
    //         ),
    //         Cell: ({ row }) => (
    //           <Checkbox {...row.getToggleRowSelectedProps()} />
    //         ),
    //       },
    //       ...columns,
    //     ];
    //   });
    // }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>{type} </h1>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      {tableData.length === 0 ? (
        <h1 className="noData">No transactions at the moment</h1>
      ) : (
        <>
          <div className="tableCont">
            <ToastContainer />

            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            <div className="pageInfo">
              {"\u00a0"} Go to page number : {"\u00a0"}
              <input
                type="number"
                default={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </div>
            <div className="buttons">
              <button
                className="jumpButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {" "}
                Go to first page
              </button>
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              {"\u00a0"}
              {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              <button
                className="jumpButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Go to last page
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionsTable;
