import React, { useState } from "react";
import styles from "../../styles/addCategory.module.css";
import chevLeft from "../../assets/icons/chevleft.png";
import tick from "../../assets/icons/saveTick.png";
import cross from "../../assets/icons/cancel2.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { Formik } from "formik";
import * as Yup from "yup";
import { ScaleLoader } from "react-spinners";
import { useRef } from "react";
import Cookies from "js-cookie";
import { fetchAllPlans } from "../../store/plans";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const UpdatePlan = () => {
  const selectedPlanId = useSelector((state) => state.plans.selectedPlanId);

  let btnRef = useRef();

  const [selectedDuration, setSelectedDuration] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});

  const durationOptions = [
    {
      value: "day",
      label: "Day",
    },
    {
      value: "week",
      label: "Week",
    },
    {
      value: "month",
      label: "Month",
    },

    {
      value: "year",
      label: "Year",
    },
  ];

  const selectedPlanObject = useSelector(
    (state) => state.plans.selectedPlanObject
  );

  const currencyOptions = [
    {
      value: "usd",
      label: "USD",
    },
  ];

  const checkDuration = () => {
    if (selectedPlanObject[0].duration.includes("month")) {
      return {
        value: "month",
        label: "Month",
      };
    }
    if (selectedPlanObject[0].duration.includes("day")) {
      return {
        value: "day",
        label: "Day",
      };
    }
    if (selectedPlanObject[0].duration.includes("year")) {
      return {
        value: "year",
        label: "Year",
      };
    }
    if (selectedPlanObject[0].duration.includes("week")) {
      return {
        value: "week",
        label: "Week",
      };
    }
  };

  const notifySuccess = () =>
    toast.success("Plan updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const schema = Yup.object().shape({
    currency: Yup.string().required("Currency is required"),
    duration: Yup.string().required("Duration is required"),
    price: Yup.string().required("Price is required"),
    name: Yup.string().required("Name is required"),
  });

  const [saveLoad, setSaveLoad] = useState(false);

  const addBanner = (body) => {
    setSaveLoad(true);
    btnRef.current.setAttribute("disabled", "disabled");

    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      currency: selectedCurrency.value,
      duration: body.duration + selectedDuration.value,
      price: body.price,
      name: body.name,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://api.angolazone.com/api/Order/plans/${selectedPlanId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.error?.code === "auth/id-token-expired") {
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
        if (result.status === true) {
          dispatch(fetchAllPlans());
          notifySuccess();
          setTimeout(() => {
            navigate("/premiumplans");
          }, 2000);
          setSaveLoad(false);
        }
      });
  };

  const onDurationSelect = (item) => {
    setSelectedDuration(item);
  };

  const onCurrencySelect = (item) => {
    setSelectedCurrency(item);
  };

  return (
    <div className="containerAdCategory">
      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate(-1)}>
          <img src={chevLeft} alt="back" />
        </button>

        <h1 className="s20">Update Plan</h1>
      </div>

      <ToastContainer />

      <Formik
        validationSchema={schema}
        initialValues={{
          currency: selectedPlanObject[0]?.currency,
          duration: selectedPlanObject[0]?.duration,
          price: selectedPlanObject[0]?.price,
          name: selectedPlanObject[0]?.name,
        }}
        onSubmit={(values) => {
          addBanner({
            currency: values.currency,
            duration: values.duration,
            price: values.price,
            name: values.name,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className={styles.addCategory}>
            <form noValidate onSubmit={handleSubmit}>
              <div className={styles.catName}>
                <label>Plan Name</label>
                <input
                  type="name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  id="planName"
                  placeholder="Enter plan name"
                />

                <p className="red">
                  {errors.name && touched.name && errors.name}
                </p>
              </div>

              <div className={`${styles.catName}`}>
                <label>Duration</label>

                <div className={styles.row}>
                  <input
                    type="duration"
                    name="duration"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.duration.slice(0, 2)}
                    placeholder="Enter plan duration"
                  />

                  <Dropdown
                    options={durationOptions}
                    onChange={(item) => onDurationSelect(item)}
                    value={checkDuration()}
                    placeholder="Select an option"
                    className="dropdown"
                    placeholderClassName="myPlaceholderClassName"
                    controlClassName="myControlClassName"
                    menuClassName="myMenuClassName"
                    arrowClassName="myArrowClassName"
                  />
                </div>

                <p className="red">
                  {errors.duration && touched.duration && errors.duration}
                </p>
              </div>

              <div className={styles.catName}>
                <label>Currency</label>

                <div className={styles.priceAndCurrency}>
                  <Dropdown
                    options={currencyOptions}
                    onChange={(item) => onCurrencySelect(item)}
                    value={selectedPlanObject[0]?.currency}
                    placeholder="Select currency"
                    className="dropdown2"
                    placeholderClassName="myPlaceholderClassName"
                    controlClassName="myControlClassName"
                    menuClassName="myMenuClassName"
                    arrowClassName="myArrowClassName"
                  />
                  <input
                    type="price"
                    name="price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    placeholder="Enter plan price"
                  />
                </div>

                <p className="red">
                  {errors.currency && touched.currency && errors.currency}
                </p>
              </div>

              <div className={styles.actionButtons}>
                <button className="btn-save" type="submit" ref={btnRef}>
                  {!saveLoad ? (
                    <>
                      <img src={tick} alt="save" /> Update
                    </>
                  ) : (
                    <ScaleLoader color="white" />
                  )}
                </button>
                <button className="btn-cancel" onClick={() => navigate(-1)}>
                  <img src={cross} alt="cancel" />
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default UpdatePlan;
