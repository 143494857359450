import React, { useState } from "react";
import styles from "../../styles/addCategory.module.css";
import chevLeft from "../../assets/icons/chevleft.png";
import tick from "../../assets/icons/saveTick.png";
import cross from "../../assets/icons/cancel2.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllCategories } from "../../store/categories";
import { Upload } from "./Upload";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { Formik } from "formik";
import * as Yup from "yup";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
import { useRef } from "react";
import Cookies from "js-cookie";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const AddBanner = () => {
  const token = Cookies.get("token");

  const [saveLoad, setSaveLoad] = useState(false);
  let btnRef = useRef();
  const [imageUploadLoader, setImageUploadLoader] = useState(false);

  const notifySuccess = () =>
    toast.success("Banner created", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const dispatch = useDispatch();

  const [uploadedFileURL, setUploadedFileUrl] = useState("");

  const navigate = useNavigate();

  const schema = Yup.object().shape({
    bannerTitle: Yup.string().required("Title is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    description: Yup.string().required("Description is required"),
  });

  const addBanner = (body) => {
    setSaveLoad(true);
    btnRef.current.setAttribute("disabled", "disabled");
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", body.token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: body.bannerTitle,
      bannerImage: uploadedFileURL,
      startDate: moment(body.startDate).format(),
      endDate: moment(body.endDate).format(),
      active: "true",
      isDeleted: "false",
      desc: body.description,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.angolazone.com/api/banner/bannercreate", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          dispatch(fetchAllCategories());
          notifySuccess();
          setTimeout(() => {
            navigate("/banners");
          }, 2000);
          setSaveLoad(false);
        }

        if (result.error?.code === "auth/id-token-expired") {
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
      })
      .catch((error) => {
        notifyError();
      });
  };
  const onFileDrop = (files) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);

    var formdata = new FormData();
    formdata.append("file", files[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.angolazone.com/api/file-upload/file", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUploadedFileUrl(result.url);
      });
  };

  return (
    <div className="containerAdCategory">
      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate("/banners")}>
          <img src={chevLeft} alt="back" />
        </button>

        <h1 className="text-Small">Add Banner</h1>
      </div>

      <ToastContainer />

      <Formik
        validationSchema={schema}
        initialValues={{
          bannerTitle: "",
          startDate: "",
          endDate: "",
          description: "",
        }}
        onSubmit={(values) => {
          addBanner({
            token,
            bannerTitle: values.bannerTitle,
            startDate: values.startDate,
            endDate: values.endDate,
            description: values.description,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className={styles.addCategory}>
            <Upload
              onDrop={(files) => onFileDrop(files)}
              imageUploadLoader={imageUploadLoader}
            />

            <form noValidate onSubmit={handleSubmit}>
              <div className={styles.catName}>
                <label>Banner Title</label>
                <input
                  type="text"
                  name="bannerTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bannerTitle}
                  id="bannerTitle"
                  placeholder="Enter banner name"
                />

                <p className="red">
                  {errors.bannerTitle &&
                    touched.bannerTitle &&
                    errors.bannerTitle}
                </p>
              </div>

              <div className={styles.catDesc}>
                <label>Description</label>
                <textarea
                  type="description"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  placeholder="Enter category description"
                />

                <p className="red">
                  {errors.description &&
                    touched.description &&
                    errors.description}
                </p>
              </div>

              <div className={styles.flexRow}>
                <div className={styles.catDesc}>
                  <label>Start Date</label>
                  <input
                    className={styles.dateInput}
                    type="date"
                    name="startDate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.startDate}
                    placeholder="Enter start date"
                    min={new Date().toISOString().split("T")[0]}
                  />

                  <p className="red">
                    {errors.startDate && touched.startDate && errors.startDate}
                  </p>
                </div>

                <div className={styles.catDesc}>
                  <label>End Date</label>
                  <input
                    className={styles.dateInput}
                    type="date"
                    name="endDate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endDate}
                    placeholder="Enter end date"
                    min={values.startDate.split("T")[0]}
                  />

                  <p className="red">
                    {errors.endDate && touched.endDate && errors.endDate}
                  </p>
                </div>
              </div>

              <div className={styles.actionButtons}>
                <button className="btn-save" type="submit" ref={btnRef}>
                  {!saveLoad ? (
                    <>
                      <img src={tick} alt="save" /> Save
                    </>
                  ) : (
                    <ScaleLoader color="white" />
                  )}
                </button>
                <button
                  className="btn-cancel"
                  onClick={() => navigate("/banners")}
                >
                  <img src={cross} alt="cancel" />
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddBanner;
