import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./GlobalFilter_Ads";
import chevRightRed from "../../assets/icons/chevRight.png";
import chevLeftRed from "../../assets/icons/chevLeftRed.png";
import styles from "../Categories Table/cat.module.css";
import { adsActions } from "../../store/ads";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import trash from "../../assets/icons/trash2.png";
import eye2 from "../../assets/icons/EyeNew.png";
import alt from "../../assets/images/gallery.svg";
import ConfirmDeleteAdModal from "./ConfirmAdDelete";
import { fetchAllAds } from "../../store/ads";
import star from "../../assets/images/premiumStar.png";
import Cookies from "js-cookie";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const AdsTable = ({ tableData, type }) => {
  const navigate = useNavigate();
  const data = useMemo(() => tableData, []);

  const dispatch = useDispatch();

  const onImageError = (ev) => {
    ev.target.src = alt;
  };

  const [loading, setLoading] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedID, setSelectedID] = useState("");

  const onRowClick = (id) => {
    dispatch(adsActions.setSelectedAd(id));
    if (type === "Deactivated ads") {
      navigate(`/ads/deactivatedAds/ad/${id}`);
    }
    if (type === "Active ads") {
      navigate(`/ads/activeAds/ad/${id}`);
    }
    if (type === "New ads") {
      navigate(`/ads/newAds/ad/${id}`);
    }
  };

  const notifySuccess = () =>
    toast.success("Ad deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const token = Cookies.get("token");

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  const onDeleteAdClick = (id) => {
    setOpenDeleteModal(true);
    setSelectedID(id);
  };

  const onDeleteClick = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.angolazone.com/api/admin/ad/${selectedID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          notifySuccess();
          dispatch(fetchAllAds());
          setOpenDeleteModal(false);
          setLoading(false);
          setTimeout(() => {
            navigate("/ads");
          }, 2000);
        }

        // if (result.status === "error") {
        //   setOpenDeleteModal(false);
        //   notifyError();
        //   setTimeout(() => {
        //     navigate("/ads");
        //   }, 3000);
        // }

        if (result.error?.code === "auth/id-token-expired") {
          setOpenDeleteModal(false);
          // setDeleteLoad(false);
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
      });
  };

  const COLUMNS_ADSTABLE = [
    {
      Header: () => <p className={styles.headerID}>Ad ID</p>,
      accessor: "id",
      Cell: (tableProps) => (
        <p className={styles.id}>
          #{String(tableProps.row.original.id).slice(-4)}
        </p>
      ),
    },
    {
      Header: () => <p className={"d-none"}></p>,
      accessor: "premium",
      Cell: (tableProps) => (
        <p className={"d-none"}>{tableProps.row.original.premium}</p>
      ),
    },

    {
      Header: "Image",
      accessor: "image",
      Cell: (tableProps) => (
        <div className="adsImageContainer">
          <img
            onError={onImageError}
            className="adsImage"
            alt="AD"
            src={tableProps.row.original.image[0]}
          />
        </div>
      ),
    },
    {
      Header: "Ad name",
      accessor: "adName",
      Cell: (tableProps) => (
        <button
          className="btn-noBorder centerAlign"
          onClick={() => onRowClick(tableProps.row.original.id)}
        >
          {tableProps.row.original.premium === "premium" ? (
            <img className="star" src={star} />
          ) : (
            ""
          )}
          {tableProps.row.original.adName}
        </button>
      ),
    },

    {
      Header: "Price",
      accessor: "price",
      Cell: (tableProps) => (
        <p className="red price">$ {tableProps.row.original.price}</p>
      ),
    },
    {
      Header: "Seller name",
      accessor: "sellerName",
      Cell: (tableProps) => (
        <p>{capitalizeName(tableProps.row.original.sellerName)}</p>
      ),
    },
    {
      Header: "Location",
      accessor: "adLocation.address",
      Cell: (tableProps) => (
        <p className="red">{`${tableProps.row.original.adLocation?.address}`}</p>
      ),
    },

    {
      Header: "Reported",
      accessor: "remarksCount",
      Cell: (tableProps) => (
        <p className="red">{`${tableProps.row.original.remarksCount}`}</p>
      ),
    },

    {
      Header: "Remark",
      accessor: "remark",
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => (
        <div className="actionsCategories">
          <button
            className="btn-delete2"
            onClick={() => onRowClick(tableProps.row.original.id)}
          >
            <img src={eye2} alt="View" />
          </button>

          <button
            className="btn-delete"
            onClick={() => onDeleteAdClick(tableProps.row.original.id)}
          >
            <img src={trash} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS_ADSTABLE, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => {
    //     return [
    //       {
    //         id: "selection",
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <Checkbox {...getToggleAllRowsSelectedProps()} />
    //         ),
    //         Cell: ({ row }) => (
    //           <Checkbox {...row.getToggleRowSelectedProps()} />
    //         ),
    //       },
    //       ...columns,
    //     ];
    //   });
    // }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>{type} </h1>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      {tableData.length === 0 ? (
        <h1 className="noData">No ads at the moment</h1>
      ) : (
        <>
          <div className="tableCont">
            <ToastContainer />
            <ConfirmDeleteAdModal
              openDeleteModal={openDeleteModal}
              setOpenDeleteModal={setOpenDeleteModal}
              onDeleteClick={onDeleteClick}
              loading={loading}
            />
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}{" "}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="tableFooters">
            <div className="pageInfo">
              {"\u00a0"} Go to page number : {"\u00a0"}
              <input
                type="number"
                default={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </div>
            <div className="buttons">
              <button
                className="jumpButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {" "}
                Go to first page
              </button>
              <button
                className="tableNav"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <img src={chevLeftRed} />
              </button>
              {"\u00a0"}
              {pageIndex + 1}
              {"\u00a0"}of {pageOptions.length}
              <button
                className="tableNav"
                disabled={!canNextPage}
                onClick={() => nextPage()}
              >
                <img src={chevRightRed} />
              </button>
              <button
                className="jumpButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Go to last page
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdsTable;
