import React from "react";
import AdsTable from "../../components/Ads Table/AdsTable";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { useEffect } from "react";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const ActiveAds = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ads.activeAds);
  const response = useSelector((state) => state.ads.getAdsResponse);

  useEffect(() => {
    if (response?.error?.code === "auth/id-token-expired") {
      dispatch(authActions.setTokenError(true));
      sessionExpireLogin();
    }
  }, [response]);

  const status = useSelector((state) => state.ads.status);

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
          </div>
        </div>
      ) : (
        <>
          {response?.error?.code === "auth/id-token-expired" ? (
            <h1 className="noData">No ads at the moment</h1>
          ) : (
            <AdsTable tableData={data} type={"Active ads"} />
          )}
        </>
      )}
    </>
  );
};

export default ActiveAds;
