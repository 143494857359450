import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children }) => {
  // const authStatusRedux = useSelector((state) => state.auth.isAuthenticated);

  // const authStateCookie = Cookies.get("isAuthenticated");

  // console.log(authStateCookie, "Auth");

  // const isAuth = authStateCookie || undefined;
  return children;
  // return isAuth ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
