import React, { useState } from "react";
import { useEffect } from "react";
import { fetchAllBanners } from "../../store/banners";
import { useDispatch, useSelector } from "react-redux";
import BannerTable from "../../components/Banner Table/BannerTable";
import { ScaleLoader } from "react-spinners";
import ConfirmDeleteBanner from "./ConfirmDeleteBanner";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import Cookies from "js-cookie";

const Banners = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllBanners());
  }, [dispatch]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const data = useSelector((state) => state.banners.allBanners);

  const status = useSelector((state) => state.banners.status);
  const token = Cookies.get("token");
  const selectedBannerId = useSelector(
    (state) => state.banners.selectedBannerID
  );
  const [loading, setLoading] = useState(false);

  const notifySuccess = () =>
    toast.success("Banner deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = (errorMessage) => {
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const deleteBanner = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.angolazone.com/api/banner/${selectedBannerId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "error") {
          notifyError("Error, All fields are required");
        }
        if (result.status === true) {
          setLoading(false);
          dispatch(fetchAllBanners());
          notifySuccess();
          setOpenDeleteModal(false);
        }
      })
      .catch((error) => {
        notifyError();
      });
  };

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
            <p className="fetchText"> Fetching banners.. </p>
          </div>
        </div>
      ) : (
        <>
          <BannerTable
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            tableData={data}
          />

          <ConfirmDeleteBanner
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            onDeleteClick={deleteBanner}
            loading={loading}
          />

          <ToastContainer />
        </>
      )}
    </>
  );
};

export default Banners;
