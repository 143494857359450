import React, { useEffect, useState } from "react";
import UsersTable from "../../components/User Table/UsersTable";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllUsers } from "../../store/users";
import { ScaleLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import ComfirmDeleteUser from "./ConfirmDeleteUser";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const ActiveUsers = () => {
  const dispatch = useDispatch();

  const id = useSelector((state) => state.users.selectedUserId);

  const response = useSelector((state) => state.users.getUserReponse);

  useEffect(() => {
    if (response?.error?.code === "auth/id-token-expired") {
      dispatch(authActions.setTokenError(true));
      sessionExpireLogin();
    }
  }, [response]);

  const notifySuccess = () =>
    toast.success("User deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const activeUsers = useSelector((state) => state.users.activeUsers);
  const status = useSelector((state) => state.users.userFetchStatus);

  return (
    <>
      {status === "loading" ? (
        <div className="containerSubRight">
          <div className="loader-container">
            <ScaleLoader color="#fc303f" />
            <p className="fetchText"> Fetching user list.. </p>
          </div>
        </div>
      ) : (
        <>
          <UsersTable
            tableData={activeUsers}
            type={"Active users"}
            setOpenDeleteModal={setOpenDeleteModal}
          />
          <ComfirmDeleteUser
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            notifySuccess={notifySuccess}
            notifyError={notifyError}
            id={id}
          />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default ActiveUsers;
