import React, { useState } from "react";
import styles from "../../styles/addCategory.module.css";
import chevLeft from "../../assets/icons/chevleft.png";
import tick from "../../assets/icons/saveTick.png";
import cross from "../../assets/icons/cancel2.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryById, fetchAllCategories } from "../../store/categories";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { Formik } from "formik";
import * as Yup from "yup";
import { UploadEdit } from "./UploadEdit";
import { BarLoader, ScaleLoader } from "react-spinners";
import { useRef } from "react";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";
import { useEffect } from "react";
import Cookies from "js-cookie";

const EditCategory = () => {
  const token = useSelector((state) => state.auth.sessionToken);
  // const allCategories = useSelector((state) => state.categories.allCategories);
  const [saveLoad, setSaveLoad] = useState(false);
  const [imageUploadLoader, setImageUploadLoader] = useState(false);
  const navigate = useNavigate();

  let btnRef = useRef();

  const selectedCategoryObject = useSelector(
    (state) => state.categories.selectedCatObject
  );

  const selectedCategoryObjectStatus = useSelector(
    (state) => state.categories.getCategoryByIdResponse
  );

  const idRaw = useParams();

  const id = String(idRaw.catergoryId).slice(1);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedCategoryObject.length) {
      dispatch(getCategoryById(id));
    }
  }, []);

  const notifySuccess = () =>
    toast.success("Category updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#1ab394 !important",
      }),
    });

  const notifyError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: css({
        background: "#ed5565 !important",
      }),
    });
  };

  const [uploadedFileURL, setUploadedFileUrl] = useState("");

  const schema = Yup.object().shape({
    categoryName: Yup.string().required("Category name is required"),
    description: Yup.string().required("Description is required"),
  });

  const [enable, setEnable] = useState(false);

  const onDisableCategory = () => {
    setEnable(true);
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      active: selectedCategoryObject[0]?.active ? false : true,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error?.code === "auth/id-token-expired") {
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
        if (result.status === true) {
          setEnable(false);
          dispatch(fetchAllCategories());
          notifySuccess();
          setTimeout(() => {
            navigate("/categories");
          }, 3000);
        }
      })
      .catch((error) => {
        notifyError();
      });
  };

  const addCategory = (body) => {
    setSaveLoad(true);
    btnRef.current.setAttribute("disabled", "disabled");
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: body.categoryName,
      image: uploadedFileURL,
      isDeleted: false,
      desc: body.description,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.angolazone.com/api/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error?.code === "auth/id-token-expired") {
          dispatch(authActions.setTokenError(true));
          sessionExpireLogin();
        }
        if (result.status === true) {
          dispatch(fetchAllCategories());
          notifySuccess();
          setTimeout(() => {
            navigate("/categories");
          }, 2000);
          setSaveLoad(false);
        }
      });
  };

  const onFileDrop = (files) => {
    setImageUploadLoader(true);

    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", token);

    var formdata = new FormData();
    formdata.append("file", files[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.angolazone.com/api/file-upload/file", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUploadedFileUrl(result.url);
        setImageUploadLoader(false);
      });
  };

  const setButtonText = () => {
    if (selectedCategoryObject[0]?.active && !enable) {
      return "Disable Category";
    }

    if (!selectedCategoryObject[0]?.active && !enable) {
      return "Enable Category";
    }

    if (enable) {
      return <BarLoader color="white" />;
    }
  };

  return (
    <div className="containerAdCategory">
      <div className="heading-container-chev">
        <button className="btn-back" onClick={() => navigate("/categories")}>
          <img src={chevLeft} alt="back" />
        </button>

        <h1 className="s20">Categories</h1>
      </div>

      <ToastContainer />

      {selectedCategoryObjectStatus === "loading" ? (
        <BarLoader color="red" />
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            categoryName: selectedCategoryObject[0]?.title,
            description: selectedCategoryObject[0]?.desc,
          }}
          onSubmit={(values) => {
            addCategory({
              token,
              categoryName: values.categoryName,
              description: values.description,
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className={styles.addCategory}>
              <button
                onClick={() => onDisableCategory()}
                className={
                  selectedCategoryObject[0]?.active
                    ? styles.deactivatedStatus
                    : styles.activeStatus
                }
                disabled={enable}
              >
                {setButtonText()}
              </button>
              <UploadEdit
                onDrop={(files) => onFileDrop(files)}
                img={selectedCategoryObject[0]?.image}
                imageUploadLoader={imageUploadLoader}
              />

              <form noValidate onSubmit={handleSubmit}>
                <div className={styles.catName}>
                  <label>Category Name</label>
                  <input
                    type="categoryName"
                    name="categoryName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.categoryName}
                    id="categoryName"
                    placeholder="Enter category name"
                  />

                  <p className="red">
                    {errors.categoryName &&
                      touched.categoryName &&
                      errors.categoryName}
                  </p>
                </div>

                <div className={styles.catDesc}>
                  <label>Description</label>
                  <textarea
                    type="description"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    placeholder="Enter category description"
                  />

                  <p className="red">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </p>
                </div>

                <div className={styles.actionButtons}>
                  <button className="btn-save" type="submit" ref={btnRef}>
                    {!saveLoad ? (
                      <>
                        <img src={tick} alt="save" /> Update
                      </>
                    ) : (
                      <ScaleLoader color="white" />
                    )}
                  </button>
                  <button
                    onClick={() => navigate("/categories")}
                    className="btn-cancel"
                  >
                    <img src={cross} alt="cancel" />
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditCategory;
