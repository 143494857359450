import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/addCategory.module.css";
import upload from "../../assets/icons/uploadIcon.png";
import { ScaleLoader } from "react-spinners";

function removeItems(arr, item) {
  for (var i = 0; i < item; i++) {
    arr.pop();
  }
}

function useFiles({ initialState = [], maxFiles }) {
  const [state, setstate] = useState(initialState);
  function withBlobs(files) {
    const destructured = [...files];
    if (destructured.length > maxFiles) {
      const difference = destructured.length - maxFiles;
      removeItems(destructured, difference);
    }
    const blobs = destructured
      .map((file) => {
        if (file.type.includes("image")) {
          file.preview = URL.createObjectURL(file);
          return file;
        }
        return null;
      })
      .filter((elem) => elem !== null);

    setstate(blobs);
  }
  return [state, withBlobs];
}

function Upload({ onDrop, maxFiles = 1, imageUploadLoader }) {
  const [over, setover] = useState(false);
  const [files, setfiles] = useFiles({ maxFiles });
  const [uploaded, setUploaded] = useState(false);

  const $input = useRef(null);
  useEffect(() => {
    if (onDrop) {
      onDrop(files);
    }
  }, [files]);
  return (
    <>
      <div
        onClick={() => {
          $input.current.click();
          setUploaded(true);
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.persist();
          setfiles(e.dataTransfer.files);
          setover(false);
          setUploaded(true);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setover(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setover(false);
        }}
        className={styles.dragAndDrop}
      >
        <div className={styles.dragAndDrop}>
          {!uploaded && (
            <>
              <img src={upload} alt="upload" />
              <h2>
                Drag and drop files, or <span className="red2">Browse</span>
              </h2>
              <p>Please upload image in 250*250 resolution</p>
            </>
          )}

          {uploaded && (
            <div className="blob-container">
              {files.map((file) => (
                <img
                  key={file.name + "file"}
                  src={file.preview}
                  alt="your file"
                />
              ))}

              {imageUploadLoader && (
                <div className="a">
                  <ScaleLoader color="red" height={"20px"} />
                </div>
              )}
            </div>
          )}
        </div>

        <input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          ref={$input}
          onChange={(e) => {
            setfiles(e.target.files);
          }}
          multiple={maxFiles > 1}
        />
      </div>
    </>
  );
}

export { Upload };
