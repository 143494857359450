import React from "react";
import Modal from "react-modal";
import styles from "../../styles/adDetail.module.css";
import alert from "../../assets/icons/saveTick.png";
import { ScaleLoader } from "react-spinners";
import cross2 from "../../assets/icons/cancel2.png";
import close from "../../assets/icons/cancelCross.png";
import Banners from "../../assets/icons/trashModal.svg";

const ConfirmDeleteCategoryModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  onDeleteClick,
  loading,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };

  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openDeleteModal}
      shouldCloseOnEsc
      onRequestClose={() => setOpenDeleteModal(false)}
      style={bg}
    >
      <div className={styles.removeAd}>
        <img
          className={styles.cancelCross}
          src={close}
          alt="X"
          onClick={() => setOpenDeleteModal(false)}
        />
        <img className="modalDelete" alt="Delete" src={Banners} />
        <h1>Are you sure want to delete this banner?</h1>
        <div className={styles.actionButtons}>
          <button
            disabled={loading ? true : false}
            onClick={() => {
              onDeleteClick();
            }}
            className="btn-save"
          >
            <img src={alert} alt="save" />
            {loading ? <ScaleLoader color="white" /> : "Delete"}
          </button>
          <button
            className="btn-cancel"
            onClick={() => setOpenDeleteModal(false)}
          >
            <img src={cross2} alt="cancel" />
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteCategoryModal;
