import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BarLoader } from "react-spinners";
import { fetchStats } from "../store/dashboard";
import styles from "../styles/dashboard.module.css";

const Dashboard = () => {
  const dispatch = useDispatch();

  const allStats = useSelector((state) => state.dashboard.dashboardStats);

  const status = useSelector(
    (state) => state.dashboard.dashboardStatsFetchStatus
  );

  useEffect(() => {
    dispatch(fetchStats());
  }, [dispatch]);

  return (
    <div className="containerSubRight">
      <div className="heading-container">
        <h1>Dashboard </h1>
      </div>

      <div className={styles.scoresContainer}>
        <div className={styles.scoreCard}>
          <h3>Total Users </h3>
          {status === "loading" ? (
            <div className="dashLoader">
              <BarLoader color="white" />{" "}
            </div>
          ) : (
            <h1>{allStats.userCount}</h1>
          )}
        </div>
        <div className={styles.scoreCard}>
          <h3>Total Ads </h3>
          {status === "loading" ? (
            <div className="dashLoader">
              <BarLoader color="white" />{" "}
            </div>
          ) : (
            <h1>{allStats.totalAds}</h1>
          )}
        </div>
        <div className={styles.scoreCard}>
          <h3>Total Premium Ads </h3>
          {status === "loading" ? (
            <div className="dashLoader">
              <BarLoader color="white" />{" "}
            </div>
          ) : (
            <h1>{allStats.premiumAds}</h1>
          )}
        </div>
        <div className={styles.scoreCard}>
          <h3>Total Earnings </h3>
          {status === "loading" ? (
            <div className="dashLoader">
              <BarLoader color="white" />{" "}
            </div>
          ) : (
            <h1>$ {allStats.totalTransactionAmount}</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
