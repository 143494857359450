import React, { useState } from "react";
import Modal from "react-modal";
import confirmRemove from "../../assets/icons/trashModal.svg";
import styles from "../../styles/adDetail.module.css";
import cross from "../../assets/icons/cancelCross.png";
import alert from "../../assets/icons/alert.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { fetchAllAds } from "../../store/ads";
import cross2 from "../../assets/icons/cancel2.png";
import Cookies from "js-cookie";
import { authActions } from "../../store/auth";
import { sessionExpireLogin } from "../../firebase";

const ConfirmActivateModal = ({
  openConfirmActivate,
  setOpenConfirmActivate,
  notifySuccess,
  id,
}) => {
  const sessionToken = Cookies.get("token");
  const selectedAdId = useSelector((state) => state.ads.selectedAdId);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const onActivateClick = (body) => {
    if (!reason) {
      setReasonError(true);
    }

    if (reason) {
      setReasonError(false);
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("firebasetoken", body.token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        adStatus: true,
        adRemark: reason,
      });

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`https://api.angolazone.com/api/admin/ad/${id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setLoading(false);
            notifySuccess();
            setOpenConfirmActivate(false);

            dispatch(
              fetchAllAds({
                token: sessionToken,
              })
            );

            setTimeout(() => {
              navigate("/ads");
            }, [2000]);
          }

          if (result.error?.code === "auth/id-token-expired") {
            setLoading(false);
            dispatch(authActions.setTokenError(true));
            sessionExpireLogin();
          }
        });
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: "fit-content",
      backgroundColor: "red",
    },
  };

  const bg = {
    overlay: {
      background: "rgb(48,48,48,0.9)",
    },
  };
  return (
    <Modal
      className={` ${customStyles}`}
      isOpen={openConfirmActivate}
      shouldCloseOnEsc
      onRequestClose={() => setOpenConfirmActivate(false)}
      style={bg}
    >
      <div className={styles.removeAd}>
        <img
          className={styles.cancelCross}
          src={cross}
          alt="X"
          onClick={() => setOpenConfirmActivate(false)}
        />
        <img className="modalDelete" alt="Delete" src={confirmRemove} />
        <h1>Please add a reason before acivating this ad.</h1>
        <textarea
          onChange={(e) => setReason(e.target.value)}
          className="reason"
        ></textarea>
        {reasonError && <p className="red">Reason is required.</p>}

        <div className={styles.actionButtons}>
          <button
            className="btn-save"
            onClick={() =>
              onActivateClick({
                token: sessionToken,
                id: selectedAdId,
              })
            }
          >
            <img src={alert} alt="save" />
            {loading ? <ScaleLoader color="white" /> : "Activate"}
          </button>
          <button
            className="btn-cancel"
            onClick={() => setOpenConfirmActivate(false)}
            disabled={loading ? true : false}
          >
            <img src={cross2} alt="cancel" />
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmActivateModal;
