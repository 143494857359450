import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialAdsState = {
  activeAds: [],
  newAds: [],
  deactivatedAds: [],
  selectedAdId: "",
  selectedAd: {},
  allAds: [],
  status: "idle",
  approveStatus: "idle",
  selectedAdObject: {},
  getAdsResponse: "",
  getAdById: [],
  getAdByIdResponse: "idle",
};

export const getAdById = createAsyncThunk("ads/getAdById", async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("firebasetoken", Cookies.get("token"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `https://api.angolazone.com/api/admin?_id=${id}`,
    requestOptions
  ).then((res) => res.json());
});

export const fetchAllAds = createAsyncThunk("ads/fetchAds", async (body) => {
  var myHeaders = new Headers();
  myHeaders.append("firebasetoken", body.token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch("https://api.angolazone.com/api/admin", requestOptions).then(
    (res) => res.json()
  );
});

const adsSlice = createSlice({
  name: "adsSlice",
  initialState: initialAdsState,
  reducers: {
    setActiveAds(state, action) {
      state.activeAds = action.payload;
    },
    setNewAds(state, action) {
      state.newAds = action.payload;
    },
    setDeactivatedAds(state, action) {
      state.deactivatedAds = action.payload;
    },
    setSelectedAd(state, action) {
      state.selectedAdId = action.payload;
      state.selectedAdObject = state.allAds.filter(
        (item) => item._id === state.selectedAdId
      );
    },
    setSelectedObject(state, action) {
      state.selectedAdObject = action.payload;
    },
  },
  extraReducers: {
    [fetchAllAds.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAllAds.fulfilled]: (state, { payload }) => {
      state.status = "succeeded";
      state.allAds = payload.products;
      state.getAdsResponse = payload;

      const activeAds = payload.products?.map((data, index) => {
        if (
          data.adIsApproved == true &&
          data.adStatus === true &&
          data.isAdSold === false
        ) {
          return {
            adId: index,
            id: data._id,
            image: data.adImage,
            adName: data.adName,
            price: data.adPrice,
            sellerName: data.userId.firstName + " " + data.userId.lastName,
            adLocation: data.adLocation,
            remark: data.adRemark,
            premium: data.adIsPremium ? "premium" : "no",
            key: data._id,
            remarksCount: data.remarksCount,
            remarks: data.remarks,
          };
        } else return {};
      });

      state.activeAds = activeAds?.filter(
        (value) => Object.keys(value).length !== 0
      );

      const newAds = payload.products?.map((data, index) => {
        if (
          data.adIsApproved === false &&
          data.adStatus === false &&
          data.isAdSold === false
        ) {
          return {
            adId: index + 1,
            id: data._id,
            image: data.adImage,
            adName: data.adName,
            price: data.adPrice,
            sellerName: data.userId.firstName + " " + data.userId.lastName,
            adLocation: data.adLocation,
            remark: data.adRemark,
            premium: "no",
            key: data._id,
            remarksCount: data.remarksCount,
            remarks: data.remarks,
          };
        } else return {};
      });

      state.newAds = newAds?.filter((value) => Object.keys(value).length !== 0);

      const deactivatedAds = payload.products?.map((data, index) => {
        if (!data.adStatus && data.adIsApproved && data.isAdSold === false) {
          return {
            adId: index + 1,
            id: data._id,
            image: data.adImage,
            adName: data.adName,
            price: data.adPrice,
            sellerName: data.userId.firstName + " " + data.userId.lastName,
            adLocation: data.adLocation,
            remark: data.adRemark,
            premium: data.adIsPremium ? "premium" : "no",
            key: data._id,
            remarksCount: data.remarksCount,
            remarks: data.remarks,
          };
        } else return {};
      });

      state.deactivatedAds = deactivatedAds?.filter(
        (value) => Object.keys(value).length !== 0
      );
    },
    [fetchAllAds.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getAdById.pending]: (state, action) => {
      state.getAdByIdResponse = "loading";
    },
    [getAdById.fulfilled]: (state, { payload }) => {
      state.getAdByIdResponse = "succeeded";
      state.selectedAdObject = payload.products;
    },
    [getAdById.rejected]: (state, action) => {
      state.getAdByIdResponse = "failed";
    },
  },
});

export const getAllAds = () => {
  return async (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("firebasetoken", Cookies.get("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const data = await axios.get(
      "https://api.angolazone.com/api/admin",
      requestOptions
    );
    dispatch(adsActions.setAllAds(data.data.data));
  };
};

export const adsActions = adsSlice.actions;

export default adsSlice;
